import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Grid, Typography, List, ListItem, Divider, ListItemText, Icon, Tooltip, colors,
} from '@material-ui/core';
import data from '../mock-summary';

import BudgetSummaryChart from '../components/BudgetSummaryChart';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 900,
    margin: 'auto',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: '2rem',
  },
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    fontSize: '0.9rem',
    margin: 2,
  },
});

class BudgetSummary extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.forceUpdate();
  }

  render() {
    const { classes, proposal } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Thank you for providing feedback on the city budget proposal!
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Invite your family and friends to participate in the budget discussion:
            </Typography>
            <div style={{
              display: 'flex', justifyContent: 'space-evenly', width: 250, height: 36, margin: 'auto',
            }}
            >
              <Tooltip title="Share on Facebook">
                <Icon className="fab fa-facebook" />
              </Tooltip>
              <Tooltip title="Share on Twitter">
                <Icon className="fab fa-twitter" />
              </Tooltip>
              <Tooltip title="Share with Email">
                <Icon className="fas fa-envelope" />
              </Tooltip>
              <Tooltip title="Copy Link">
                <Icon className="fas fa-link" />
              </Tooltip>
            </div>
            <Typography variant="subtitle1" gutterBottom>
              Below you can see how your proposal compares with the city proposal as well as
               the community aggregated proposal.  The curve and the grey bars show the distribution
                of proposals your fellow residents have submitted.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.legend}>
              <div className={classes.legendItem}>
                <svg width="15" height="15" fill={colors.red[200]} viewBox="0 0 20 20">
                  <rect x="4" y="4" height="12" width="12" />
                </svg>
                Major consequences
              </div>
              <div className={classes.legendItem}>
                <svg width="15" height="15" fill={colors.amber[200]} viewBox="0 0 20 20">
                  <rect x="4" y="4" height="12" width="12" />
                </svg>
                Minor consequences
              </div>
              <div className={classes.legendItem}>
                <svg width="15" height="15" fill={colors.grey[300]} viewBox="0 0 20 20">
                  <rect x="4" y="4" height="12" width="12" />
                </svg>
                Implications
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.legend}>
              <div className={classes.legendItem}>
                <svg width="15" height="15" fill={colors.grey[600]} viewBox="0 0 20 20">
                  <rect x="4" y="4" height="12" width="12" />
                </svg>
                Community Proposals
              </div>
              <div className={classes.legendItem}>
                <svg width="15" height="15" fill={colors.blue[600]} viewBox="0 0 20 20">
                  <circle cx="10" cy="10" r="6" />
                </svg>
                City Proposal
              </div>
              <div className={classes.legendItem}>
                <svg width="15" height="15" fill={colors.teal[600]} viewBox="0 0 20 20">
                  <circle cx="10" cy="10" r="6" />
                </svg>
                Community Aggregated Proposal
              </div>
              {
                proposal.items
                  ? (
                    <div className={classes.legendItem}>
                      <svg width="15" height="15" fill={colors.purple[600]} viewBox="0 0 20 20">
                        <circle cx="10" cy="10" r="6" />
                      </svg>
                      Your Proposal
                    </div>
                  )
                  : null
              }
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <List className={classes.paper}>
              {
                data.items.map((item) => (
                  <div key={item.id}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={item.name}
                        secondary={(
                          <>
                            <BudgetSummaryChart
                              item={item}
                              userProposal={((proposal.items || {})[item.id] || {}).amount}
                            />
                          </>
                        )}
                      />
                    </ListItem>
                    <Divider component="li" />
                  </div>
                ))
              }
            </List>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default connect((state) => state.budget, {})(withStyles(styles)(BudgetSummary));
