import {
  AppBar, Grid, Toolbar, Typography, Divider, Link, colors,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

import { withRouter, Route, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import BudgetIcon from '../BudgetIcon';
import AustinPredictionWelcome from './AustinPredictionWelcome';
import AustinOverview from './AustinOverview';
import AustinRevenue from './AustinRevenue';
import AustinExpenditure from './AustinExpenditure';
import AustinThankyou from './AustinThankyou';
import AustinSurvey from './AustinSurvey';
import ToS from './pages/ToS';
import ScrollToTop from '../ScrollToTop';
import { useT, useCode } from './hooks';

export const AUSTIN_PREDICTION_BASE_URL = '/austin2020-prediction';

const styles = (theme) => ({
  main: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  root: {
    flexGrow: 1,
    flex: '1 0 100%',
    maxWidth: 900,
    margin: 'auto',
  },
  headerTitle: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  headerTag: {
    backgroundColor: colors.blue[500],
    color: 'white',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  footer: {
    '& hr': {
      margin: theme.spacing(2, 0),
    },
    textAlign: 'center',
    '& .MuiTypography-root': {
      fontFamily: 'Roboto Condensed',
    },
  },
});

const components = [
  { step: 'overview', component: AustinOverview },
  { step: 'revenue', component: AustinRevenue, intent: 'resident' },
  { step: 'expenditure', component: AustinExpenditure, intent: 'resident' },
  { step: 'survey', component: AustinSurvey, intent: 'self' },
];

const order = ['overview', 'revenue', 'expenditure', 'survey', 'thankyou'];

function getNext(step, order) {
  return `${AUSTIN_PREDICTION_BASE_URL}/${order[order.indexOf(step) + 1]}`;
}

const AustinPredictionMain = ({ classes }) => {
  const t = useT();
  const code = useCode();
  return (
    <div>
      <ScrollToTop />
      <AppBar position="static" color="inherit">
        <Toolbar>
          <BudgetIcon />
          <Typography variant="h6" color="primary" className={classes.headerTitle}>
            <span className={classes.headerTag}>Prediction</span>
            {t('austin.header-title')}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.main}>
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Route exact path={AUSTIN_PREDICTION_BASE_URL} render={() => <AustinPredictionWelcome nextUrl={`${AUSTIN_PREDICTION_BASE_URL}/overview`} />} />
              {components.map(({ step, component: Component, intent }) => (
                <Route
                  key={step}
                  exact
                  path={`${AUSTIN_PREDICTION_BASE_URL}/${step}`}
                  render={(props) => {
                    if (!code) {
                      return <Redirect push to={AUSTIN_PREDICTION_BASE_URL} />;
                    }
                    const comp = (<Component {...props} nextUrl={getNext(step, order)} />);
                    if (intent) {
                      if (intent === 'resident') {
                        return (
                          <>
                            <Alert severity="info" variant="filled">For the following section, please enter how you think a typical Austinite will vote.</Alert>
                            {comp}
                          </>
                        );
                      } else if (intent === 'self') {
                        return (
                          <>
                            <Alert severity="info">The following section is about you, not a typical Austinite.</Alert>
                            {comp}
                          </>
                        );
                      }
                    }
                    return comp;
                  }}
                />
              ))}
              <Route exact path={`${AUSTIN_PREDICTION_BASE_URL}/thankyou`} component={AustinThankyou} />
              <Route exact path={`${AUSTIN_PREDICTION_BASE_URL}/terms`} component={ToS} />
            </Grid>
            <Grid item xs={12} className={classes.footer}>
              <Divider />
              <Typography>
                <Link variant="body2" href="https://voxpopuli.stanford.edu/" target="_blank" rel="noopener">Stanford Crowdsourced Democracy Team</Link>
              </Typography>
              <Link color="secondary" variant="body2" href={`${AUSTIN_PREDICTION_BASE_URL}/terms`} target="_blank" rel="noopener">Terms and Services</Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(AustinPredictionMain));
