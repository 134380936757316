import {
  colors, Grid,
  Paper, Radio, RadioGroup, Slider,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import React, { useCallback, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import {
  AllBudgetItems, DepartmentData,
} from './LongBeachDepartmentData';
import LongBeachNextBar from './LongBeachNextBar';
import { log, request } from '../../api/client';
import { useCode, useSlug, useUserLanguage } from '../austin/hooks';
import Budget from './pages/Budget';
import formatters from '../../util/formatters';

const LONG_BEACH_DEFICIT = 30000000;
const STEP = 'budget';

const styles = (theme) => ({
  radioLabel: {
    width: '275px',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  radioGroup: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  paper: {
    padding: theme.spacing(2),
    fontFamily: 'Roboto',
    textAlign: 'left',
    border: `1px solid ${theme.palette.divider}`,
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
  questionHeader: {
    fontSize: '1.4rem',
    borderLeft: `16px solid ${colors.grey[400]}`,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    paddingLeft: 8,
    marginBottom: 20,
    marginTop: 20,
    fontFamily: 'Roboto Condensed',
  },
  questionText: {
    flexGrow: 1,
    paddingRight: 10,
  },
  questionSubDescription: {
    fontSize: '1rem',
  },
  questionMoney: {
    textAlign: 'right',
    minWidth: 150,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 5,
      textAlign: 'left',
    },
  },
  itemValue: {
    fontSize: '.8rem',
    color: grey,
  },
  totalBudgetCalculation: {
    position: '-webkit-sticky',
    // eslint-disable-next-line no-dupe-keys
    position: 'sticky',
    top: '0px',
    fontSize: '2rem',
    fontFamily: 'Roboto Condensed',
    textAlign: 'center',
    paddingBottom: 10,
    marginBottom: 10,
    zIndex: 2,
  },
  totalBudgetInstruction: {
    fontSize: '1rem',
    textAlign: 'center',
    marginTop: '5px',
    marginBottom: '5px',
  },
  linearBarPrimary: {
    backgroundColor: 'rgb(129, 199, 132)',
  },
  linearBarRoot: {
    height: 15,
  },
  departmentBox: {
    border: '1px solid rgba(0, 0, 0, .125)',
    fontFamily: 'Roboto Condensed',
    padding: '10px',
    marginBottom: '10px',
  },
  departmentHeader: {
    fontSize: '2rem',
    display: 'flex',
    fontFamily: 'Roboto Condensed',
  },
  departmentName: {
    flexGrow: 1,
  },
  checkItem: {
    marginBottom: 10,
  },
  departmentDescription: {
    fontSize: '14px',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontFamily: 'Roboto Condensed',
  },
  sliderDiv: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  root: {
    flexGrow: 1,
    flex: '1 0 100%',
    maxWidth: 900,
    margin: 'auto',
  },
  headerTitle: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

function DepartmentBox(props) {
  const {
    classes, departmentName, children, departmentDescription,
  } = props;
  return (
    <div className={classes.departmentBox}>
      <div className={classes.departmentHeader}>
        <div className={classes.departmentName}>{departmentName}</div>
      </div>
      <div className={classes.departmentDescription}>{departmentDescription}</div>
      {children}
    </div>
  );
}

const getDollarAmountKMB = (value) => {
  if (value > 0) {
    return `Cost $${formatters.kmb(value)}`;
  } if (value < 0) {
    return `Save $${formatters.kmb(value * -1)}`;
  }
  return `$${value.toLocaleString()}`;
};

function PolicySlider(props) {
  const {
    classes, policyInfo, changeFunction, expense,
  } = props;
  const code = useCode();
  const slug = useSlug();
  const [curSliderValue, setCurSliderValue] = useState(expense[policyInfo.item]);
  const mark = [{ value: policyInfo.min, label: policyInfo.minLabel }, { value: policyInfo.max, label: policyInfo.maxLabel }];
  if (policyInfo.min !== 0 && policyInfo.max !== 0) {
    mark.push({
      value: 0,
      label: 'no change',
    });
  }
  return (
    <>
      <div className={classes.questionHeader}>
        <div className={classes.questionText}>
          {policyInfo.item}
          {policyInfo.itemNote && <div className={classes.questionSubDescription}>{policyInfo.itemNote}</div>}
        </div>
        <div className={classes.questionMoney}>{getDollarAmountKMB(curSliderValue || 0)}</div>
      </div>
      <div className={classes.sliderDiv}>
        <Slider
          min={policyInfo.min}
          step={policyInfo.step}
          max={policyInfo.max}
          marks={mark}
          track={false}
          onChangeCommitted={(event, value) => {
            log(code, 'UPDATE_SLIDER', {
              step: STEP, id: policyInfo.item, sliderValue: value, moneyValue: value * policyInfo.value,
            }, slug);
          }}
          onChange={(event, value) => { setCurSliderValue(value * policyInfo.value); changeFunction(policyInfo.item, value * policyInfo.value); }}
          valueLabelDisplay="auto"
          defaultValue={expense[policyInfo.item] / policyInfo.value}
        />
      </div>
    </>
  );
}

function PolicyRadio(props) {
  const {
    classes, policyInfo, changeFunction, expense,
  } = props;
  const code = useCode();
  const slug = useSlug();
  const [curRadioValue, setCurRadioValue] = useState(expense[policyInfo.item]);
  return (
    <>
      <div className={classes.questionHeader}>
        <div className={classes.questionText}>
          {policyInfo.item}
          {policyInfo.itemNote && <div className={classes.questionSubDescription}>{policyInfo.itemNote}</div>}
        </div>
        <div className={classes.questionMoney}>{getDollarAmountKMB(curRadioValue || 0)}</div>
      </div>
      <RadioGroup
        name={policyInfo.item}
        row
        className={classes.radioGroup}
        value={expense[policyInfo.item].toString()}
        onChange={(event) => {
          log(code, 'UPDATE_OPTION', { step: STEP, id: policyInfo.item, value: parseInt(event.target.value, 10) }, slug);
          changeFunction(policyInfo.item, parseInt(event.target.value, 10));
          setCurRadioValue(parseInt(event.target.value, 10));
        }}
      >
        {policyInfo.radioGroup.map((radioObject) => (
          <FormControlLabel
            className={classes.radioLabel}
            value={radioObject.value.toString()}
            control={<Radio />}
            label={(
              <div>
                <div>{radioObject.option}</div>
                <div className={classes.itemValue}>
(
                  {getDollarAmountKMB(radioObject.value)}
)
                </div>
              </div>
)}
          />
        ))}
      </RadioGroup>
    </>
  );
}

function PolicyCheck(props) {
  const {
    classes, policyInfo, expense, changeFunction,
  } = props;
  const code = useCode();
  const slug = useSlug();
  const [totalMoney, setTotalMoney] = useState(() => {
    let total = 0;
    for (const item of policyInfo.checkGroup) {
      total += expense[item.item];
    }
    return total;
  });
  return (
    <>
      <div className={classes.questionHeader}>
        <div className={classes.questionText}>
            Select additional department changes
        </div>
        <div className={classes.questionMoney}>{getDollarAmountKMB(totalMoney)}</div>
      </div>
      {policyInfo.checkGroup.map((item) => (
        <div className={classes.checkItem}>
          <FormControlLabel
            control={(
              <Checkbox
                defaultChecked={item.value === expense[item.item]}
                value={item.value}
                onChange={(event) => {
                  log(code, 'UPDATE_CHECKBOX', {
                    step: STEP, id: item.item, value: item.value, checked: event.target.checked,
                  }, slug);
                  if (event.target.checked) {
                    changeFunction(item.item, item.value);
                    setTotalMoney(totalMoney + item.value);
                  } else {
                    changeFunction(item.item, 0);
                    setTotalMoney(totalMoney - item.value);
                  }
                }}
              />
                  )}
            label={(
              <div>
                <div>{item.item}</div>
                <div className={classes.itemValue}>
                    (
                  {getDollarAmountKMB(item.value)}
                    )
                </div>
              </div>
            )}
          />
        </div>
      ))}
    </>
  );
}

const PolicyItems = { slider: PolicySlider, radio: PolicyRadio, check: PolicyCheck };

function Policy(props) {
  const {
    classes, policyInfo, expense, changeFunction,
  } = props;
  const Component = PolicyItems[policyInfo.itemType];
  return (<Component classes={classes} changeFunction={changeFunction} expense={expense} policyInfo={policyInfo} />);
}

const LongBeachBudgetFeedbackPage = (props) => {
  const {
    classes, nextUrl, setLongBeachState, longBeachState,
  } = props;
  const [expenses, setExpenses] = useState(longBeachState[STEP]);
  const changeExpense = (key, value) => {
    setExpenses({ ...expenses, [key]: value });
  };
  const changeToBudget = () => {
    let total = 0;
    for (const expense of Object.values(expenses)) {
      if (typeof expense === 'number') {
        total += expense;
      }
    }
    return total;
  };
  const totalDeficit = LONG_BEACH_DEFICIT + changeToBudget();
  const code = useCode();
  const slug = useSlug();
  const userLanguage = useUserLanguage();
  const [error, setError] = useState('');
  const history = useHistory();
  const next = useCallback(
    () => {
      request('/api/save', 'POST', {
        code, step: STEP, lang_tag: userLanguage, response: JSON.stringify({ ...expenses, version: 'v2' }), slug,
      }).then((response) => {
        if (!response.ok) {
          setError('error-next');
        } else {
          setLongBeachState({ ...longBeachState, [STEP]: { ...expenses, version: 'v2' } });
          history.push(nextUrl);
        }
      });
    },
    [code, STEP, userLanguage, setError, setLongBeachState, longBeachState, history, nextUrl, slug, expenses],
  );

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <Budget />
      </Paper>
      <Paper className={classes.totalBudgetCalculation}>
        <div>
          { totalDeficit > 0 && `Remaining Deficit: $${(totalDeficit).toLocaleString()}`}
          { totalDeficit > 0 && <div className={classes.totalBudgetInstruction}>You need to eliminate the remaining deficit in order to move on.</div>}
          { totalDeficit <= 0 && `Surplus: $${(Math.abs(totalDeficit)).toLocaleString()}`}
          { totalDeficit <= 0 && <div className={classes.totalBudgetInstruction}>You can now move on and submit, or you can keep making budget changes.</div>}
          <LinearProgress classes={{ barColorPrimary: classes.linearBarPrimary, root: classes.linearBarRoot }} color="secondary" variant="determinate" value={Math.min(100 - 100 * ((changeToBudget() + LONG_BEACH_DEFICIT) / LONG_BEACH_DEFICIT), 100)} />
        </div>
      </Paper>
      <Paper className={classes.paper} elevation={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {DepartmentData.map((department) => (
              <DepartmentBox classes={classes} departmentName={department.departmentName} departmentDescription={department.departmentDescription}>
                {AllBudgetItems[department.departmentKey].map((policy) => (<Policy classes={classes} expense={expenses} policyInfo={policy} changeFunction={changeExpense} />))}
              </DepartmentBox>
            ))}
          </Grid>
          <LongBeachNextBar error={error} disabled={totalDeficit > 0} disabledPrompt="balance-prompt" onClick={next} />
        </Grid>
      </Paper>
    </>
  );
};

export default withStyles(styles)(LongBeachBudgetFeedbackPage);
