import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@material-ui/core';
import CityBudgetSummaryChart from './CityBudgetSummaryChart';
import util from '../util/budgetUtil';


const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    maxWidth: 720,
  },
});

function BalanceDialog(props) {
  const {
    budgetDetails, proposal, onClose, onSave, ...other
  } = props;
  const balanced = util.scaleAll(budgetDetails, proposal);
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Balance Options</DialogTitle>
      <DialogContent>
        <List>
          <ListItem button divider selected>
            <ListItemText primary="Scale all budget items proportionally" />
          </ListItem>
          <ListItem button divider>
            <ListItemText primary="Some other options" secondary="Further explanation of the option" />
          </ListItem>
          <ListItem button divider disabled>
            <ListItemText primary="Issue tax credit" secondary="Only available when there is surplus in your current proposal" />
          </ListItem>
        </List>
        <DialogTitle id="confirmation-dialog-title">Preview</DialogTitle>
        <CityBudgetSummaryChart proposalText="Balanced" budget={budgetDetails} proposal={balanced} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSave} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(BalanceDialog);
