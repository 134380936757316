import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Paper,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red, grey, amber } from '@material-ui/core/colors';
import {
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend,
} from 'recharts';
import data from '../mock-items';
import formatters from '../util/formatters';
import colors from '../util/palette';
import BudgetSummaryChart from '../components/BudgetSummaryChart';


const categoryColor = [grey[300], amber[200], red[200]];

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  cardContent: {
    padding: theme.spacing(1),
    maxHeight: 360,
    overflowY: 'scroll',
  },
});

const ItemView = (props) => {
  const { classes } = props;
  const { itemId } = useParams();
  const item = data.items.find((item) => String(item.id) === itemId);
  if (!item) {
    return null;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          {item.name}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Paper className={classes.root}>
          <Typography variant="h6" gutterBottom>
            Purpose Statement
          </Typography>
          <div className={classes.cardContent}>
            {
              item.statement.map((item) => (
                <ExpansionPanel key={item.id}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">
                      {item.name}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      {item.text}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))
            }
          </div>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Paper className={classes.root}>
          <Typography variant="h6" gutterBottom>
            Trends
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={item.trend}
              margin={{
                top: 20, right: 30, left: 20, bottom: 5,
              }}
            >
              <XAxis dataKey="year" />
              <YAxis tickFormatter={formatters.kmb} />
              <Tooltip formatter={(value) => formatters.kmb(value)} />
              <Legend />
              {
                item.statement.map(({ id, name }, idx) => <Bar key={id} dataKey={name} stackId="a" fill={colors[idx % colors.length][600]} />)
              }
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Paper className={classes.root}>
          <Typography variant="h6" gutterBottom>
            Consequences
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BudgetSummaryChart item={item} />
          </ResponsiveContainer>

          {
            item.flags.map((flag) => (

              <Typography component="p" key={flag.id} gutterBottom>
                <svg width="18" height="18" fill={categoryColor[flag.level]} viewBox="0 0 20 20">
                  <rect x="4" y="8" height="12" width="12" />
                </svg>
                When allotted budget is higher than
                {' '}
                {formatters.kmb(flag.lower)}
                {' '}
                and lower than
                {' '}
                {formatters.kmb(flag.upper)}
                ,
                {' '}
                {flag.explanation || `this is consequence ${flag.id}.`}
              </Typography>
            ))
          }
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Paper className={classes.root}>
          <Typography variant="h6" gutterBottom>
            Comments
          </Typography>
          <List className={classes.cardContent}>
            {
              item.comments.map((comment) => (
                <ListItem key={comment.id} alignItems="flex-start">
                  <ListItemText
                    primary={comment.text}
                    secondary={`${comment.name}, ${comment.region}`}
                  />
                </ListItem>
              ))
            }
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect((state) => state, {})(withStyles(styles)(ItemView));
