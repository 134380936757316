import React from 'react';
import { Divider, Grid, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';

function LongBeachNextBar(props) {
  const {
    error, disabled, disabledPrompt, onClick,
  } = props;
  const t = useSelector((state) => state.options.t);
  const errorMsg = error ? t(error) : null;
  return (
    <Grid item xs={12}>
      <Divider />
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1, textAlign: 'right', padding: 8 }}>{disabled ? t(disabledPrompt) : errorMsg}</div>
        <Button variant="contained" style={{ float: 'right' }} color="primary" disabled={disabled} onClick={onClick}>
          {t('next')}
        </Button>
      </div>
    </Grid>
  );
}

export default LongBeachNextBar;
