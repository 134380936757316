import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router, Route, Redirect, Switch,
} from 'react-router-dom';
import { grey } from '@material-ui/core/colors';
import './App.css';
import AppHeader from './AppHeader';
import AppContent from './AppContent';
import Landing from '../components/Landing';
import AustinMain, { AUSTIN_BASE_URL } from '../components/austin/AustinMain';
import AustinPredictionMain, { AUSTIN_PREDICTION_BASE_URL } from '../components/austin/AustinPredictionMain';
import LongBeachMain, { LONG_BEACH_BASE_URL } from '../components/longBeach/LongBeachMain';

const pbtheme = createMuiTheme({
  palette: {
    primary: {
      light: '#909FAF',
      main: '#2c3945',
    },
    secondary: {
      //   light: '#AE7857',
      main: '#5790b7',
    },
    background: {
      default: '#eee',
    },
    text: {
      primary: '#3c4858',
      link: grey[600],
    },
  },
});

function renderDetails(props) {
  return (
    <>
      <AppHeader />
      <AppContent {...props} />
    </>
  );
}

function App(props) {
  return (
    <MuiThemeProvider theme={pbtheme}>
      <Router>
        <Switch>
          <Route path="/budget" render={() => renderDetails(props)} />
          <Route path={AUSTIN_BASE_URL} component={AustinMain} />
          <Route path={AUSTIN_PREDICTION_BASE_URL} component={AustinPredictionMain} />
          <Route path={LONG_BEACH_BASE_URL} component={LongBeachMain} />
          <Redirect from="/fee" to={AUSTIN_BASE_URL} />
          <Route exact path="/" component={Landing} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
