import * as materialColors from '@material-ui/core/colors';

export const palette = [
  'deepPurple',
  'blue',
  'orange',
  'teal',
  'brown',
  'cyan',
  'blueGrey',
  'indigo',
  'deepOrange',
  'grey',
  'pink',
  'yellow',
  'purple',
  'lightBlue',
  'lime',
];

export default palette.map((name) => materialColors[name]);
