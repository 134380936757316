import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import budget from './budget-reducer';
import introjs from './introjs-reducer';
import options from './options-reducer';
import user from './user-reducer';
import responses from './response-reducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  budget,
  introjs,
  options,
  user,
  responses,
});
