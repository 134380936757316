import {
  AppBar, colors, Divider, Grid, Link, Paper, Radio, RadioGroup, Slider, Toolbar, Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Redirect, Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import ScrollToTop from '../ScrollToTop';
import BudgetIcon from '../BudgetIcon';
import LanguageSettings from '../LanguageSettings';
import LongBeachBudgetFeedbackPage from './LongBeachBudgetFeedbackPage';
import ToS from './pages/ToS';
import LongBeachWelcome from './LongBeachWelcome';
import LongBeachOverview from './LongBeachOverview';
import LongBeachConsented from './LongBeachConsented';
import LongBeachSurvey from './LongBeachSurvey';
import LongBeachThankyou from './LongBeachThankyou';
import { LongBeachInitState } from './LongBeachDepartmentData';

export const LONG_BEACH_BASE_URL = '/longBeach2020';

const styles = (theme) => ({
  main: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  root: {
    flexGrow: 1,
    flex: '1 0 100%',
    maxWidth: 900,
    margin: 'auto',
  },
  headerTitle: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  footer: {
    '& hr': {
      margin: theme.spacing(2, 0),
    },
    textAlign: 'center',
    '& .MuiTypography-root': {
      fontFamily: 'Roboto Condensed',
    },
  },
});

const components = [
  { step: 'overview', component: LongBeachOverview },
  { step: 'budget', component: LongBeachBudgetFeedbackPage },
  { step: 'consent', component: LongBeachConsented },
  { step: 'survey', component: LongBeachSurvey },
];

const defaultOrder = ['overview', 'budget', 'consent', 'survey', 'thankyou'];

function getNext(step, order) {
  return `${LONG_BEACH_BASE_URL}/${order[order.indexOf(step) + 1]}`;
}

const LongBeachMain = ({ classes }) => {
  const t = useSelector((state) => state.options.t);
  const code = useSelector((state) => state.user.code);
  const [longBeachState, setLongBeachState] = useState(() => JSON.parse(localStorage.getItem('LongBeachState')) || LongBeachInitState);
  useEffect(() => {
    localStorage.setItem('LongBeachState', JSON.stringify(longBeachState));
  }, [longBeachState]);
  const order = defaultOrder;
  return (
    <div>
      <ScrollToTop />
      <AppBar position="static" color="inherit">
        <Toolbar>
          <BudgetIcon />
          <Typography variant="h6" color="primary" className={classes.headerTitle}>
            {t('longBeach.header-title')}
          </Typography>
          <LanguageSettings />
          {/* <div className="help-button">
            <IconButton
              color="inherit"
            >
              <Help />
            </IconButton>
          </div> */}
        </Toolbar>
      </AppBar>
      <div className={classes.main}>
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Route exact path={LONG_BEACH_BASE_URL} render={() => <LongBeachWelcome nextUrl={`${LONG_BEACH_BASE_URL}/overview`} />} />
              {components.map(({ step, component: Component }) => (
                <Route
                  key={step}
                  exact
                  path={`${LONG_BEACH_BASE_URL}/${step}`}
                  render={(props) => {
                    if (!code) {
                      return <Redirect push to={LONG_BEACH_BASE_URL} />;
                    }
                    return (<Component {...props} longBeachState={longBeachState} setLongBeachState={setLongBeachState} nextUrl={getNext(step, order)} />);
                  }}
                />
              ))}
              <Route exact path={`${LONG_BEACH_BASE_URL}/thankyou`} component={LongBeachThankyou} />
              <Route exact path={`${LONG_BEACH_BASE_URL}/terms`} component={ToS} />
            </Grid>
            <Grid item xs={12} className={classes.footer}>
              <Divider />
              <Typography>
                <Link variant="body2" href="https://voxpopuli.stanford.edu/" target="_blank" rel="noopener">Stanford Crowdsourced Democracy Team</Link>
              </Typography>
              <Link color="secondary" variant="body2" href={`${LONG_BEACH_BASE_URL}/terms`} target="_blank" rel="noopener">Terms and Services</Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(LongBeachMain));
