import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper, Grid, Typography, colors, Button,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { useT } from '../austin/hooks';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    fontFamily: 'Roboto Condensed',
    textAlign: 'left',
    border: `1px solid ${theme.palette.divider}`,
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
  container: {
    padding: '5vh 0',
    justifyContent: 'center',
  },
  item: {
    textAlign: 'center',
  },
  title: {
    fontSize: '1.2rem',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
});

function LongBeachThankyou(props) {
  const { classes } = props;
  const t = useT();
  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} className={classes.item}>
          <CheckCircle style={{ fontSize: '4rem', color: colors.green[600] }} />
        </Grid>
        <Grid item xs={10} className={classes.item}>
          <Typography variant="h4" className={classes.title}>
            {t('longBeach.thankyou')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Button
            variant="contained"
            color="primary"
            href="http://www.longbeach.gov/pages/city-news/fiscal-year-2021-proposed-budget/"
            target="_blank"
          >
            {t('longBeach.learn-more')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(LongBeachThankyou);
