import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';
import _ from 'lodash';
import * as d3 from 'd3';
import * as d3Scale from 'd3-scale';

const levelColor = [colors.grey, colors.amber, colors.red];
const styles = () => ({});

// from https://bl.ocks.org/mbostock/4341954
function kernelDensityEstimator(kernel, X) {
  return function estimator(V) {
    return X.map((x) => [x, d3.mean(V, (v) => kernel(x - v))]);
  };
}

function kernelEpanechnikov(k) {
  return (v) => {
    v /= k;
    return Math.abs(v) <= 1 ? (0.75 * (1 - v * v)) / k : 0;
  };
}

class BudgetSummaryChart extends Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
  }

  componentDidMount() {
    this.updateChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }


  updateChart() {
    const { userProposal } = this.props;
    const {
      min, max, proposed, suggested, flags,
    } = this.props.item;
    const svg = d3.select(this.svgRef.current);
    const width = this.svgRef.current.clientWidth;
    const height = this.svgRef.current.clientHeight;
    const margin = {
      top: 10, right: 10, bottom: 20, left: 10,
    };
    const nbins = Math.floor((width - margin.left - margin.right) / 10);
    svg.selectAll('g').remove();
    const x = d3.scaleLinear()
      .domain([min, max])
      .range([margin.left, width - margin.right]);
    const bins = d3.histogram().domain([min, max]).thresholds(nbins)(proposed);
    const ymax = d3.max(bins.map((l) => l.length)) / proposed.length;
    const y = d3Scale.scaleLinear()
      .domain([0, ymax])
      .range([height - margin.bottom, margin.top]);
    svg.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).tickArguments([5, 's']));
    const density = kernelDensityEstimator(kernelEpanechnikov(min / 10), x.ticks(nbins))(proposed);
    svg.insert('g', '*')
      .attr('fill', '#bbb')
      .selectAll('rect')
      .data(bins)
      .enter()
      .append('rect')
      .attr('x', (d) => x(d.x0) + 1)
      .attr('y', (d) => y(d.length / proposed.length))
      .attr('width', (d) => x(d.x1) - x(d.x0) - 1)
      .attr('height', (d) => y(0) - y(d.length / proposed.length));

    svg.insert('g', '*')
      .selectAll('rect')
      .data(flags)
      .enter()
      .append('rect')
      .attr('fill', (d) => levelColor[d.level][200])
      .attr('x', (d) => x(d.lower))
      .attr('y', height - margin.bottom - 5)
      .attr('width', (d) => x(d.upper) - x(d.lower))
      .attr('height', 10);

    svg.append('g', '*').append('path')
      .datum(density)
      .attr('fill', 'none')
      .attr('stroke', colors.grey[600])
      .attr('stroke-width', 1.5)
      .attr('stroke-linejoin', 'round')
      .attr('d', d3.line()
        .curve(d3.curveBasis)
        .x((d) => x(d[0]))
        .y((d) => y((d[1] * min) / 100)));

    svg.append('g', '*').append('circle')
      .attr('fill', colors.blue[600])
      .attr('stroke', colors.blue[600])
      .attr('stroke-width', 3)
      .attr('cx', x(suggested))
      .attr('cy', height - margin.bottom)
      .attr('r', 3);

    svg.append('g', '*').append('circle')
      .attr('fill', colors.teal[600])
      .attr('stroke', colors.teal[600])
      .attr('stroke-width', 3)
      .attr('cx', x(_.mean(proposed)))
      .attr('cy', height - margin.bottom)
      .attr('r', 3);

    if (userProposal) {
      svg.append('g', '*').append('circle')
        .attr('fill', colors.purple[600])
        .attr('stroke', colors.purple[600])
        .attr('stroke-width', 3)
        .attr('cx', x(userProposal))
        .attr('cy', height - margin.bottom)
        .attr('r', 3);
    }
  }

  render() {
    return (
      <svg width="100%" height="100%" fontFamily="Roboto Condensed" ref={this.svgRef} />
    );
  }
}

export default withStyles(styles)(BudgetSummaryChart);
