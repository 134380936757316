import React, { Component, Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import {
  TextField, Tooltip, Grid, Typography, Paper, ExpansionPanel, ExpansionPanelSummary,
  ExpansionPanelDetails, Divider, IconButton, SvgIcon, Hidden, Button,
} from '@material-ui/core';
import { ExpandMore, Info } from '@material-ui/icons';

import {
  updateItemComment, updateItemAmount, resetItemProposal, updateSubitemAmount,
} from '../actions/budget-action';
import formatters from '../util/formatters';
import ComparisonBar from './ComparisonBar';
import SliderInput from './SliderInput';
import ConsequenceDetails from './ConsequenceDetails';
import CityBudgetItemSummary from './CityBudgetItemSummary';

const IncDot1 = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 24" style={{ width: 35 }}>
    <text x="17.5" y="18" textAnchor="middle" fontFamily="Roboto" fontSize="18" fontWeight="700">+0.1</text>
  </SvgIcon>
);

const DecDot1 = (props) => (
  <SvgIcon {...props} viewBox="0 0 35 24" style={{ width: 35 }}>
    <path d="M0.4 11.2h8.5v2.5h-8.5z" />
    <text x="17.5" y="18" textAnchor="middle" fontFamily="Roboto" fontSize="18" fontWeight="700">‒0.1</text>
  </SvgIcon>
);

const Dec1 = (props) => (
  <SvgIcon {...props}>
    <path d="M2.7 11.2h8.5v2.5h-8.5z" />
    <text x="12" y="18" textAnchor="middle" fontFamily="Roboto" fontSize="18" fontWeight="700">‒1</text>
  </SvgIcon>
);

const Inc1 = (props) => (
  <SvgIcon {...props}>
    <text x="12" y="18" textAnchor="middle" fontFamily="Roboto" fontSize="18" fontWeight="700">+1</text>
  </SvgIcon>
);

const styles = (theme) => ({
  content: {
    margin: 0,
    minHeight: 36,
    '&$expanded': {
      margin: 0,
      minHeight: 48,
    },
  },
  expanded: {
    // needed for &$expanded above
  },
  expandIcon: {
    padding: 0,
  },
  label: {
    fontFamily: 'Roboto Condensed',
    whiteSpace: 'nowrap',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    padding: 2,
    display: 'flex',
    alignItems: 'center',
    margin: 2,
  },
  iconButton: {
    padding: '8px 2px',
    borderRadius: '20%',
    minWidth: '42px',
    color: theme.palette.text.link,
  },
  divider: {
    width: 1,
    height: 30,
  },
  text: {
    padding: 6,
    userSelect: 'none',
    whiteSpace: 'nowrap',
  },
  details: {
    padding: '0 24px 8px',
    overflow: 'hidden',
  },
  actions: {
    textAlign: 'right',
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
});

function CityBudgetItemTopLevelInput(props) {
  const {
    classes, proposed, onChange, details,
  } = props;
  const { id } = details;
  const minus1 = useCallback(() => { onChange({ id, delta: -1e6 }); }, [id, onChange]);
  const minusPoint1 = useCallback(() => { onChange({ id, delta: -1e5 }); }, [id, onChange]);
  const plusPoint1 = useCallback(() => { onChange({ id, delta: 1e5 }); }, [id, onChange]);
  const plus1 = useCallback(() => { onChange({ id, delta: 1e6 }); }, [id, onChange]);
  const onValueChange = useCallback((_event, value) => {
    onChange({ id, value });
  }, [id, onChange]);
  return (
    <>
      <Grid item xs={12}>
        <Typography>
          {details.description || 'Some more text description on the budget item.'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item xs={12} sm={6} style={{ alignItems: 'center', display: 'flex' }}>
            <Paper className={classes.root} elevation={1}>
              <IconButton className={classes.iconButton} onClick={minus1}>
                <Dec1 />
              </IconButton>
              <Divider className={classes.divider} />
              <IconButton className={classes.iconButton} onClick={minusPoint1}>
                <DecDot1 />
              </IconButton>
              <Divider className={classes.divider} />
              <div className={classes.text}>
                {formatters.kmb(proposed.amount)}
                {' '}
                (
                {formatters.kmb(proposed.diff, '+')}
                )
              </div>
              <Divider className={classes.divider} />
              <IconButton className={classes.iconButton} onClick={plusPoint1}>
                <IncDot1 />
              </IconButton>
              <Divider className={classes.divider} />
              <IconButton className={classes.iconButton} onClick={plus1}>
                <Inc1 />
              </IconButton>
            </Paper>
          </Grid>
          <ConsequenceDetails consequence={proposed.consequence} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SliderInput
          proposed={proposed}
          details={details}
          onChange={onValueChange}
        />
      </Grid>
    </>
  );
}

function CityBudgetItemNestedInputFragment(props) {
  const {
    id, classes, details, proposed, onChange, pid,
  } = props;
  const minus1 = useCallback(() => { onChange({ pid, id, delta: -1e6 }); }, [pid, id, onChange]);
  const minusP1 = useCallback(() => { onChange({ pid, id, delta: -1e5 }); }, [pid, id, onChange]);
  const plusP1 = useCallback(() => { onChange({ pid, id, delta: 1e5 }); }, [pid, id, onChange]);
  const plus1 = useCallback(() => { onChange({ pid, id, delta: 1e6 }); }, [pid, id, onChange]);
  const onValueChange = useCallback((_event, value) => {
    onChange({ pid, id, value });
  }, [pid, id, onChange]);
  return (
    <Fragment key={id}>
      <Grid item xs={12}>
        {details.readonly
          ? (
            <div>
              <span style={{ paddingLeft: 6 }}>
                {details.name}
              </span>
              <Tooltip title={details.description} classes={{ tooltip: classes.tooltip }}>
                <IconButton style={{ padding: 6 }}>
                  <Info />
                </IconButton>
              </Tooltip>
              <span style={{ marginTop: 10, float: 'right' }}>
                {formatters.kmb(details.suggested)}
              </span>
            </div>
          ) : (
            <>
              <span style={{ paddingLeft: 6 }}>
                {details.name}
              </span>
              <Tooltip title={details.description} classes={{ tooltip: classes.tooltip }}>
                <IconButton style={{ padding: 6 }}>
                  <Info />
                </IconButton>
              </Tooltip>
              <IconButton className={classes.iconButton} onClick={minus1}>
                <Dec1 />
              </IconButton>
              <IconButton className={classes.iconButton} onClick={minusP1}>
                <DecDot1 />
              </IconButton>
              <IconButton className={classes.iconButton} onClick={plusP1}>
                <IncDot1 />
              </IconButton>
              <IconButton className={classes.iconButton} onClick={plus1}>
                <Inc1 />
              </IconButton>
              <span style={{ marginTop: 10, float: 'right' }}>
                {formatters.kmb(proposed.amount)}
                {' '}
                (
                {formatters.kmb(proposed.amount - details.suggested, '+')}
                )
              </span>
            </>
          )}
      </Grid>
      <Grid item xs={12}>
        <SliderInput
          proposed={proposed}
          details={details}
          onChange={onValueChange}
        />
      </Grid>
    </Fragment>
  );
}

function CityBudgetItemNestedInput(props) {
  const {
    classes, proposed, onChange, details,
  } = props;
  return (
    <Grid item xs={12}>
      <Grid container spacing={0} style={{ alignItems: 'center' }}>
        {Object.values(details.subitems).map((item) => (
          <CityBudgetItemNestedInputFragment
            key={item.id}
            id={item.id}
            classes={classes}
            details={item}
            proposed={proposed.subitems[item.id]}
            onChange={onChange}
            pid={details.id}
          />
        ))}
      </Grid>
    </Grid>
  );
}

function CityBudgetItemDetails(props) {
  const {
    classes, proposed, details, maxVal, color,
    onAmountUpdate, onCommentUpdate, onReset, onSubitemAmountUpdate,
  } = props;
  const { url } = useRouteMatch();
  const onCommentChange = useCallback((event) => {
    onCommentUpdate({ id: details.id, value: event.target.value });
  }, [details.id, onCommentUpdate]);
  const onResetItem = useCallback(() => {
    onReset(details.id);
  }, [details.id, onReset]);
  const nested = proposed.subitems != null;
  const InputForm = nested
    ? CityBudgetItemNestedInput
    : CityBudgetItemTopLevelInput;
  const onFormUpdate = nested
    ? onSubitemAmountUpdate
    : onAmountUpdate;
  return (
    <Grid container spacing={1}>
      <Hidden smUp>
        <Grid item xs={3} className={classes.container}>
          <div className={classes.label}>{formatters.kmb(proposed.amount)}</div>
        </Grid>
        <Grid item xs={9}>
          <ComparisonBar
            oldVal={details.suggested}
            newVal={proposed.amount}
            maxVal={maxVal}
            color={color}
          />
        </Grid>
      </Hidden>
      <InputForm
        classes={classes}
        proposed={proposed}
        details={details}
        onChange={onFormUpdate}
      />
      <Grid item xs={12}>
        <TextField
          label="Comment (Optional)"
          fullWidth
          margin="normal"
          variant="outlined"
          value={proposed.comment}
          onChange={onCommentChange}
        />
      </Grid>
      <Grid item className={classes.actions} xs={12}>
        <Button size="small" onClick={onResetItem}>Reset</Button>
        <Button size="small" variant="outlined" href={`${url}/item/${details.id}`} target="_blank">Learn More</Button>
      </Grid>
    </Grid>
  );
}

class CityBudgetItem extends Component {
  state = {
    expanded: false,
    nupdate: -1,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.nupdate > state.nupdate) {
      return { expanded: props.expanded, nupdate: props.nupdate };
    }
    return state;
  }

  handleExpansion = (_event, expanded) => {
    this.setState({ expanded });
  }

  render() {
    const {
      classes, idx, maxVal, color, proposed, details,
      updateItemComment, resetItemProposal, updateItemAmount, updateSubitemAmount,
    } = this.props;
    const { expanded } = this.state;
    const diff = proposed.amount - details.suggested;
    const diffPct = (diff * 100) / details.suggested;
    return (
      <ExpansionPanel className={`budget-item-${idx}`} expanded={expanded} onChange={this.handleExpansion}>
        <ExpansionPanelSummary
          classes={{
            root: classes.content,
            content: classes.content,
            expanded: classes.expanded,
            expandIcon: classes.expandIcon,
          }}
          expandIcon={<ExpandMore />}
        >
          <CityBudgetItemSummary
            proposed={proposed}
            details={details}
            maxVal={maxVal}
            color={color}
            diffPct={diffPct}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <CityBudgetItemDetails
            classes={classes}
            proposed={proposed}
            details={details}
            maxVal={maxVal}
            color={color}
            onCommentUpdate={updateItemComment}
            onAmountUpdate={updateItemAmount}
            onSubitemAmountUpdate={updateSubitemAmount}
            onReset={resetItemProposal}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default connect(() => ({}), {
  updateItemComment, updateItemAmount, resetItemProposal, updateSubitemAmount,
})(withStyles(styles)(CityBudgetItem));
