function kmb(num, positiveSign = '') {
  const posSign = num > 0 ? positiveSign : '';
  if (num === 0) return '0';
  const sign = num < 0 ? '–' : posSign;
  let postfix = '';
  let number = Math.abs(num);
  if (number >= 1e6) {
    number /= 1e6;
    postfix = 'M';
  } else if (number > 1e3) {
    number /= 1e3;
    postfix = 'K';
  }
  return `${sign}${number.toFixed(2)}${postfix}`;
}

export default {
  kmb,
};
