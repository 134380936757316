import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { CheckCircle, Cancel } from '@material-ui/icons';
import {
  Paper, Grid, Divider, colors,
} from '@material-ui/core';

import formatters from '../util/formatters';
import CityBudgetSummaryChart from './CityBudgetSummaryChart';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    fontFamily: 'Roboto Condensed',
    textAlign: 'center',
  },
  info: {
    fontSize: '1.5rem',
  },
  badge: {
    fontSize: '1.5rem',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function getBalanceSummary(classes, diff) {
  if (diff > 0) {
    return (
      <div className={classes.badge}>
        <Cancel style={{ color: colors.red[600], margin: '0 5px' }} />
        {formatters.kmb(diff)}
        {' '}
        Surplus
      </div>
    );
  } if (diff < 0) {
    return (
      <div className={classes.badge}>
        <Cancel style={{ color: colors.red[600], margin: '0 5px' }} />
        {formatters.kmb(-diff)}
        {' '}
        Deficit
      </div>
    );
  }
  return (
    <div className={classes.badge}>
      <CheckCircle style={{ color: colors.green[600], margin: '0 5px' }} />
      Balanced
    </div>
  );
}

function CityBudgetSummary(props) {
  const { classes, budgetDetails, proposal } = props;
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4}>
          <div>Available Budget</div>
          <div className={classes.info}>
            {formatters.kmb(budgetDetails.amount)}
          </div>
        </Grid>
        <Grid item xs={6} sm={4}>
          <div>Allotted Budget</div>
          <div className={classes.info}>
            {formatters.kmb(proposal.amount)}
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className="budget-balance">
          {
            getBalanceSummary(classes, budgetDetails.amount - proposal.amount)
          }
        </Grid>
      </Grid>
      <Divider style={{ margin: '10px 0' }} />
      <CityBudgetSummaryChart budget={budgetDetails} proposal={proposal} />
    </Paper>
  );
}

export default connect((state) => {
  const { budgetDetails, proposal } = state.budget;
  return {
    budgetDetails,
    proposal,
  };
})(withStyles(styles)(CityBudgetSummary));
