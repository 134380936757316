import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export function useSlug() {
    const location = useLocation();
    return location.pathname.split('/')[1];
}

export function useT() {
    return useSelector((state) => state.options.t);
}

export function useUserLanguage() {
    return useSelector((state) => state.options.userLanguage);
}

export function useCode() {
    return useSelector((state) => state.user.code);
}

export function useResponse(step) {
    return useSelector((state) => state.responses[step]);
}
