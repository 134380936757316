import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, FormControlLabel } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Input from '@material-ui/core/Input';
import Overview from './pages/Overview';
import { request } from '../../api/client';
import {
  useCode, useUserLanguage, useSlug, useResponse,
} from '../austin/hooks';
import LongBeachNextBar from './LongBeachNextBar';

const STEP = 'overview';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    fontFamily: 'Roboto',
    textAlign: 'left',
    border: `1px solid ${theme.palette.divider}`,
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
  zipCode: {
    display: 'flex',
    justifyContent: 'center',
  },
});

function LongBeachOverview(props) {
  const {
    classes, nextUrl, setLongBeachState, longBeachState,
  } = props;
  const userLanguage = useUserLanguage();
  const slug = useSlug();
  const history = useHistory();
  const code = useCode();
  const [zipCode, setZipCode] = useState(longBeachState[STEP].zipCode);
  const [error, setError] = useState('');
  const next = useCallback(
    () => {
      request('/api/save', 'POST', {
        code, step: STEP, lang_tag: userLanguage, response: JSON.stringify({ zipCode }), slug,
      }).then((response) => {
        if (!response.ok) {
          response.json().then((body) => {
            setError(body.message || 'error-next');
          }).catch(() => {
            setError('error-next');
          });
        } else {
          setLongBeachState({ ...longBeachState, [STEP]: { zipCode } });
          history.push(nextUrl);
        }
      });
    },
    [code, userLanguage, setError, history, longBeachState, setLongBeachState, nextUrl, slug, zipCode],
  );
  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Overview />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.zipCode}>
            {/* <div>Please enter your zip code to continue:</div> */}
            <FormControlLabel labelPlacement="top" label="Please enter your zip code to continue:" control={<Input defaultValue={zipCode} onChange={(event) => setZipCode(event.target.value )} inputProps={{ maxLength: 5 }} />} />
          </div>
        </Grid>
        <LongBeachNextBar error={error} disabled={zipCode.length !== 5} disabledPrompt="longBeach.zipcode-error" onClick={next} />
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(LongBeachOverview);
