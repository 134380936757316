import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import cx from 'clsx';
import {
  Grid, Divider, colors, Typography, FormControl, NativeSelect,
  TextField, FormControlLabel, RadioGroup, Radio, FormGroup, Checkbox,
} from '@material-ui/core';
import { log } from '../api/client';
import { useCode, useSlug, useT } from './austin/hooks';

const styles = (theme) => ({
  questionContainer: {
    margin: theme.spacing(2, 0, 1, 0),
    textAlign: 'left',
  },
  questionText: {
    fontSize: '1.2rem',
    borderLeft: `16px solid ${colors.grey[400]}`,
    paddingLeft: 8,
    fontFamily: 'Roboto Condensed',
  },
  subquestionContainer: {
    margin: '0 16px',
    padding: '0 16px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
    },
  },
  option: {
    flex: '0 0 100%',
  },
  radiogroup: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    padding: theme.spacing(1),
  },
  matrixHeader: {
    display: 'table-header-group',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  matrixChoiceLabel: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
    },
  },
  matrixChoiceLabelRoot: {
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
  },
  matrixRow: {
    display: 'table-row',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  matrixRowHeader: {
    display: 'table-cell',
    padding: theme.spacing(2, 0),
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: colors.grey[100],
      width: `calc(100% - ${theme.spacing(4)}px)`,
      borderLeft: `${theme.spacing(2)}px solid ${colors.blueGrey[100]}`,
      padding: theme.spacing(0, 2),
      lineHeight: '2.2rem',
      fontSize: '1.1rem',
    },
  },
  matrixRadioGroup: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& div': {
      flex: '1 1 auto',
      margin: theme.spacing(1),
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        margin: theme.spacing(0, 1),
      },
    },
  },
});

function SurveyHeader(props) {
  const {
    classes, color, text, index,
  } = props;
  return (
    <Grid item xs={12} className={classes.questionContainer}>
      <Typography variant="h4" className={classes.questionText} style={{ borderLeftColor: color[400] }}>
        {index == null ? '' : `${index}. `}
        {text}
      </Typography>
    </Grid>
  );
}


export function RadioOptions(props) {
  const {
    response, setResponse, id, name, options, classes, step, className,
  } = props;
  const code = useCode();
  const slug = useSlug();
  const onChange = useCallback((event) => {
    setResponse({ ...response, [id]: event.target.value });
    log(code, 'UPDATE_OPTION', { id, step, option: event.target.value }, slug);
  }, [code, step, response, setResponse, id, slug]);
  return (
    <RadioGroup
      aria-label={name}
      name={name}
      row
      value={response[id] || ''}
      onChange={onChange}
      className={cx(classes.radiogroup, className)}
    >
      {
        options.map(({ text, value }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio color="secondary" />}
            label={text}
            labelPlacement="end"
            className={classes.option}
          />
        ))
      }
    </RadioGroup>
  );
}

export function CheckboxOptions(props) {
  const {
    response, setResponse, id, name, options, classes, step,
  } = props;
  const code = useCode();
  const slug = useSlug();
  const onChange = useCallback((event) => {
    const values = event.target.checked ? [...new Set(response[id].concat(event.target.value))]
      : response[id].filter((val) => val !== event.target.value);
    setResponse({ ...response, [id]: values });
    log(code, 'UPDATE_CHECKBOX', {
      id, step, value: event.target.value, checked: event.target.checked,
    }, slug);
  }, [response, setResponse, id, code, step, slug]);
  return (
    <FormGroup
      aria-label={name}
      name={name}
      row
      className={classes.radiogroup}
    >
      {
        options.map(({ text, value }) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Checkbox color="secondary" checked={response[id].indexOf(value) >= 0} value={value} onChange={onChange} />}
            label={text}
            labelPlacement="end"
            className={classes.option}
          />
        ))
      }
    </FormGroup>
  );
}

export function DropdownOptions(props) {
  const {
    response, setResponse, id, name, options, classes, step,
  } = props;
  const code = useCode();
  const slug = useSlug();
  const onChange = useCallback((event) => {
    setResponse({ ...response, [id]: event.target.value });
    log(code, 'UPDATE_OPTION', { id, step, option: event.target.value }, slug);
  }, [response, setResponse, id, code, step, slug]);
  return (
    <FormControl className={classes.formControl}>
      <NativeSelect
        value={response[id] || ''}
        onChange={onChange}
        className={classes.selectEmpty}
        inputProps={{ 'aria-label': name }}
      >
        {
          options.map(({ text, value, disabled }) => (
            <option key={value} value={value} disabled={disabled}>{text}</option>
          ))
        }
      </NativeSelect>
    </FormControl>
  );
}


export function DropdownOptionsWithOther(props) {
  const {
    response, setResponse, id, name, options, classes, step,
  } = props;
  const t = useT();
  const code = useCode();
  const slug = useSlug();
  const SEP = '!';
  const onChange = useCallback((event) => {
    setResponse({ ...response, [id]: event.target.value });
    log(code, 'UPDATE_OPTION', { id, step, option: event.target.value }, slug);
  }, [response, setResponse, id, code, step, slug]);
  const onTextChange = useCallback((event) => {
    const option = 'other' + SEP + event.target.value;
    setResponse({ ...response, [id]: option });
  }, [response, setResponse, id, code, step, slug]);
  const seg = (response[id] || '').split(SEP);
  return (
    <FormControl className={classes.formControl}>
      <NativeSelect
        value={seg[0]}
        onChange={onChange}
        className={classes.selectEmpty}
        inputProps={{ 'aria-label': name }}
      >
        {
          options.map(({ text, value, disabled }) => (
            <option key={value} value={value} disabled={disabled}>{text}</option>
          ))
        }
        <option key="other" value="other">{t('austin.survey.other')}</option>
      </NativeSelect>
      {seg[0] === 'other' ? <TextField value={seg[1] || ''} margin="normal" placeholder="Please Specify" onChange={onTextChange} /> : null}
    </FormControl>
  );
}

function MatrixTableRow(props) {
  const {
    response, setResponse, id, classes, scale, sid, text, step,
  } = props;
  const code = useCode();
  const slug = useSlug();
  const onChange = useCallback((event) => {
    const subresponse = { ...response[id], [sid]: event.target.value };
    log(code, 'UPDATE_OPTION', { id: `${id}.${sid}`, step, option: event.target.value }, slug);
    setResponse({ ...response, [id]: subresponse });
  }, [response, setResponse, id, sid, code, step, slug]);
  return (
    <div key={sid} className={classes.matrixRow}>
      <div className={classes.matrixRowHeader}>
        {text}
      </div>
      <RadioGroup
        aria-label={sid}
        name={sid}
        value={(response[id] || {})[sid] || ''}
        onChange={onChange}
        className={classes.matrixRadioGroup}
      >
        {scale.map(({ text, value }) => (
          <div key={value}>
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio color="secondary" name={sid} />}
              label={text}
              labelPlacement="end"
              classes={{
                root: classes.matrixChoiceLabelRoot,
                label: classes.matrixChoiceLabel,
              }}
            />
          </div>
        ))}
      </RadioGroup>
    </div>
  );
}

export function MatrixTable(props) {
  const {
    response, setResponse, id, classes, scale, statements, step,
  } = props;
  return (
    <>
      <div className={classes.matrixHeader}>
        <div className={classes.matrixRow}>
          <div className={classes.matrixRowHeader} />
          <div className={classes.matrixRadioGroup}>
            {scale.map(({ text, value }) => (
              <div key={value} style={{ width: `${60 / scale.length}%` }}>
                {text}
              </div>
            ))}
          </div>
        </div>
      </div>
      {statements.map((statement) => (
        <MatrixTableRow
          key={statement.sid}
          response={response}
          setResponse={setResponse}
          id={id}
          step={step}
          classes={classes}
          scale={scale}
          {...statement}
        />
      ))}
    </>
  );
}

export function Text(props) {
  const {
    response, setResponse, id, classes, ...other
  } = props;
  const onChange = useCallback((event) => {
    setResponse({ ...response, [id]: event.target.value });
  }, [response, setResponse, id]);
  return (
    <TextField
      margin="normal"
      variant="outlined"
      value={response[id] || ''}
      onChange={onChange}
      {...other}
    />
  );
}

function SurveyQuestion(thisprops) {
  const {
    classes, color, text, response, setResponse, Component, id, name, props, index, step,
  } = thisprops;
  return (
    <>
      <Divider />
      <SurveyHeader classes={classes} color={color} text={text} index={index} />
      <Grid item xs={12} className={classes.subquestionContainer}>
        <Component
          classes={classes}
          id={id}
          name={name || id}
          text={text}
          step={step}
          response={response}
          setResponse={setResponse}
          {...props}
        />
      </Grid>
    </>
  );
}

export default withStyles(styles)(SurveyQuestion);
