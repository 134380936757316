import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions/response-action';

const initialState = {
  overview: {},
  revenue: {
    animal: '', health: '', ems: '', fire: '', aquatic: '', program: '', golf: '', rental: '', zoning: '', taxrate: '',
  },
  expenditure: {
    animal: 0,
    ems: 0,
    fire: 0,
    health: 0,
    library: 0,
    court: 0,
    housing: 0,
    park: 0,
    zoning: 0,
    other: 0,
    police: 0,
    comment: '',
  },
  survey: {
    gender: [],
    age: '',
    hispanic: '',
    race: [],
  },
};

const responseReducer = createReducer(initialState, {
  [actions.updateStepResponse]: (state, { payload }) => {
    state[payload.step] = payload.response;
  },
  [actions.resetResponses]: () => initialState,
});

export default responseReducer;
