import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  red, grey, amber, blue,
} from '@material-ui/core/colors';
import Slider from '@material-ui/core/Slider';

const categoryColor = [grey, amber, red];

const styles = () => ({
  sliderContainer: {
    display: 'flex',
    height: 36,
    position: 'relative',
  },
  slider: {
    marginTop: -6,
  },
  sliderTrack: {
    opacity: 0,
    height: 8,
  },
  sliderThumb: {
    backgroundColor: blue[600],
    height: 16,
    width: 16,
    marginTop: 0,
    marginLeft: -7,
  },
  mark: {
    height: 4,
    marginLeft: -1,
    marginTop: 5,
    backgroundColor: '#54494888',
  },
  markLabel: {
    top: 28,
  },
});

function SliderInput(props) {
  const {
    classes, proposed, details, onChange, ...rest
  } = props;
  if (details.readonly) {
    return null;
  }
  const { min, max } = details;
  const range = (max - min) / 100;
  return (
    <div className={classes.sliderContainer}>
      <svg width="100%" height="36" style={{ position: 'absolute' }}>
        <rect x="0" y="14" width="100%" height="8" stroke="none" strokeWidth="0" fill={blue[100]} />
        <g>
          {
            details.flags.map((flag) => {
              const {
                id, lower, upper, level,
              } = flag;
              if (!lower && !upper) return null;
              const low = lower || min;
              const up = upper || max;
              const start = (low - min) / range;
              const len = (up - low) / range;
              return (
                <rect
                  key={id}
                  x={`${start.toFixed(1)}%`}
                  y="14"
                  width={`${len.toFixed(1)}%`}
                  height="8"
                  stroke="none"
                  strokeWidth="0"
                  fill={categoryColor[level][200]}
                />
              );
            })
          }
        </g>
      </svg>
      <Slider
        classes={{
          container: classes.slider,
          track: classes.sliderTrack,
          rail: classes.sliderTrack,
          thumb: classes.sliderThumb,
          mark: classes.mark,
          markLabel: classes.markLabel,
        }}
        value={proposed.amount}
        min={details.min}
        max={details.max}
        step={100000}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
}

export default withStyles(styles)(SliderInput);
