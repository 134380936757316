const LANGUAGES_LABEL = [
  {
    code: 'en',
    text: 'English',
  },
  // {
  //   code: 'es',
  //   text: 'Español',
  // },
  // {
  //   code: 'zh',
  //   text: '中文',
  // },
  // {
  //   code: 'vi',
  //   text: 'Tiếng Việt',
  // },
];

const LANGUAGES_CODES = LANGUAGES_LABEL.map((lang) => lang.code);

module.exports = {
  LANGUAGES_LABEL,
  LANGUAGES_CODES,
};
