import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Grid, Button } from '@material-ui/core';
import { Steps } from 'intro.js-react';
import { useRouteMatch } from 'react-router-dom';
import CityBudgetSummary from '../components/CityBudgetSummary';
import CityBudgetDetails from '../components/CityBudgetDetails';
import SurveyDialog from '../components/SurveyDialog';
import BalanceDialog from '../components/BalanceDialog';
import { resetProposal, balanceBudget } from '../actions/budget-action';
import { endIntro } from '../actions/introjs-action';

import 'intro.js/introjs.css';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 900,
    margin: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: '2rem',
  },
  summary: {
    margin: theme.spacing(0, 2),
  },
  buttons: {
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: '4px 15px',
  },
  buttonGroup: {
    display: 'inline-flex',
    flexDirection: 'column',
  },
  link: {
    color: theme.palette.text.link,
    fontSize: '0.85rem',
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline',
    padding: theme.spacing(2),
  },
  dialogRoot: {
    backgroundColor: 'transparent',
    margin: 'auto',
    maxWidth: 720,
  },
});

function CityBudget(props) {
  const {
    classes, budget, introjs, endIntro, onReset, balanceBudget, history,
  } = props;
  const [balanceOpen, setBalanceState] = useState(false);
  const [surveyOpen, setSurveyState] = useState(false);
  const { url } = useRouteMatch();
  const openBalance = useCallback(() => { setBalanceState(true); }, [setBalanceState]);
  const handleBalance = useCallback(() => {
    balanceBudget();
    setBalanceState(false);
  }, [setBalanceState, balanceBudget]);
  const closeBalance = useCallback(() => { setBalanceState(false); }, [setBalanceState]);
  const openSurvey = useCallback(() => { setSurveyState(true); }, [setSurveyState]);
  const closeSurvey = useCallback(() => { setSurveyState(false); }, [setSurveyState]);
  const reset = useCallback(() => { onReset(); }, [onReset]);
  const onSubmit = useCallback(() => {
    if (history) history.push(`${url}/summary`);
  }, [url, history]);
  if (_.isEmpty(budget.budgetDetails)) {
    return <div className={classes.root} />;
  }
  const inbalanced = budget.budgetDetails.amount !== budget.proposal.amount;
  return (
    <div className={classes.root}>
      <Steps
        enabled={introjs.enabled}
        steps={introjs.steps}
        initialStep={0}
        onExit={endIntro}
        options={{ showStepNumbers: false, exitOnOverlayClick: false }}
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CityBudgetSummary />
        </Grid>
        <Grid item xs={12}>
          <CityBudgetDetails />
        </Grid>
        <Grid item xs={12} className={classes.buttons}>
          <Button variant="contained" color="secondary" onClick={openBalance} className={classes.button}>
            Help me balance
          </Button>
          <div className={classes.buttonGroup}>
            <Button variant="contained" color="primary" disabled={inbalanced} onClick={openSurvey} className={classes.button}>
              Submit
            </Button>
            <div className={classes.link} onClick={reset}>Reset all</div>
          </div>
        </Grid>
      </Grid>
      <BalanceDialog
        classes={{
          root: classes.dialogRoot,
          paper: classes.paper,
        }}
        budgetDetails={budget.budgetDetails}
        proposal={budget.proposal}
        open={balanceOpen}
        onClose={closeBalance}
        onSave={handleBalance}
      />
      <SurveyDialog
        classes={{
          root: classes.dialogRoot,
          paper: classes.paper,
        }}
        proposal={budget.proposal}
        open={surveyOpen}
        onCancel={closeSurvey}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default connect((state) => state, {
  endIntro, onReset: resetProposal, balanceBudget,
})(withStyles(styles)(CityBudget));
