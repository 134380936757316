import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions/user-action';


const userReducer = createReducer({}, {
  [actions.init]: (state, { payload }) => {
    state.code = payload.code;
    state.researchParams = payload.researchParams;
    state.consented = false;
  },
  [actions.updateConsent]: (state, { payload }) => {
    state.consented = payload;
  },
});

export default userReducer;
