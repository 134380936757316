import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { request } from '../../api/client';
import Consent from './pages/Consent';
import { updateConsent } from '../../actions/user-action';
import { useSlug, useT, useCode } from './hooks';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(4),
    fontFamily: 'Roboto Condensed',
    textAlign: 'left',
    border: `1px solid ${theme.palette.divider}`,
    '& hr': {
      margin: theme.spacing(1, 0),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  options: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& small': {
      padding: 8,
    },
  },
});


function AustinConsent(props) {
  const { classes, nextUrl } = props;
  const t = useT();
  const history = useHistory();
  const dispatch = useDispatch();
  const code = useCode();
  const slug = useSlug();
  const [error, setError] = useState('');
  const next = useCallback(
    (event) => {
      const consented = event.currentTarget.value === '1';
      dispatch(updateConsent(consented));
      request('/api/consent', 'POST', { code, consented, slug }).then((response) => {
        if (!response.ok) {
          setError('error-next');
        } else {
          history.push(nextUrl);
        }
      });
    },
    [dispatch, code, history, nextUrl, slug],
  );

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Consent />
        </Grid>
        <Grid item xs={12} className={classes.options}>
          {error ? t(error) : null}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} className={classes.option}>
              <small>{t('austin.consent.consent-text')}</small>
              <Button variant="contained" color="primary" value="1" onClick={next}>{t('austin.consent.consent-button')}</Button>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.option}>
              <small>{t('austin.consent.noconsent-text')}</small>
              <Button variant="contained" color="primary" value="0" onClick={next}>{t('austin.consent.noconsent-button')}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(AustinConsent);
