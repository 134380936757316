import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper, Grid, Button, colors, Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { request } from '../../api/client';
import { init } from '../../actions/user-action';
import { resetResponses } from '../../actions/response-action';
import SurveyQuestion, {
  RadioOptions, CheckboxOptions, DropdownOptions, MatrixTable, Text, DropdownOptionsWithOther,
} from '../SurveyQuestion';
import { useT, useUserLanguage, useCode, useSlug } from './hooks';

const STEP = 'survey';


const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    fontFamily: 'Roboto Condensed',
    textAlign: 'left',
    border: `1px solid ${theme.palette.divider}`,
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
  title: {
    fontSize: '1.2rem',
    padding: theme.spacing(2, 0),
    fontFamily: 'Roboto Condensed',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  formControl: {
    margin: theme.spacing(1),
  },
  error: {
    textAlign: 'center',
  },
});

const AUSTIN_DEPARTMENTS = [
  'Health & Environment',
  'Culture & Lifelong Learning',
  'Safety',
  'Government that Works for All',
  'Economic Opportunity & Affordability',
  'Mobility',
  'Budget Office',
  // 'Other',
  'Not a City Employee'
];

function predictionSurvey(t) {
  return [
    {
      id: 'department',
      text: 'What is your department, or what strategic outcome is your department aligned to?',
      color: colors.blueGrey,
      Component: DropdownOptionsWithOther,
      props: {
        options: [{ text: t('austin.survey.-'), value: '', disabled: true }].concat(
          AUSTIN_DEPARTMENTS.map(dept => ({ text: dept, value: dept.toLowerCase().replace(/\W+/g, '-') }))),
      },
    },
    {
      id: 'specific-involvement',
      text: 'Are you involved in the organizing of this specific consultation process?',
      color: colors.blueGrey,
      Component: RadioOptions,
      props: {
        options: [
          { text: t('yes'), value: 'yes' },
          { text: t('no'), value: 'no' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'generic-involvement',
      text: 'Do you consider yourself involved with the city budget process?',
      color: colors.blueGrey,
      Component: RadioOptions,
      props: {
        options: [
          { text: t('yes'), value: 'yes' },
          { text: t('no'), value: 'no' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'gender',
      text: t('austin.survey.gender.question'),
      color: colors.blueGrey,
      Component: CheckboxOptions,
      props: {
        options: [
          { text: t('austin.survey.gender.female'), value: 'female' },
          { text: t('austin.survey.gender.trans-female'), value: 'trans-female' },
          { text: t('austin.survey.gender.male'), value: 'male' },
          { text: t('austin.survey.gender.trans-male'), value: 'trans-male' },
          { text: t('austin.survey.gender.queer'), value: 'queer' },
          { text: t('austin.survey.gender.agender'), value: 'agender' },
          { text: t('austin.survey.gender.questioning'), value: 'questioning' },
          // { text: t('austin.survey.other'), value: 'other' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'age',
      text: t('austin.survey.age.question'),
      color: colors.blueGrey,
      Component: DropdownOptions,
      props: {
        options: [
          { text: t('austin.survey.-'), value: '', disabled: true },
          { text: t('austin.survey.age.18-'), value: '18-' },
          { text: t('austin.survey.age.18-24'), value: '18-24' },
          { text: t('austin.survey.age.25-34'), value: '25-34' },
          { text: t('austin.survey.age.35-44'), value: '35-44' },
          { text: t('austin.survey.age.45-54'), value: '45-54' },
          { text: t('austin.survey.age.55-64'), value: '55-64' },
          { text: t('austin.survey.age.65-74'), value: '65-74' },
          { text: t('austin.survey.age.75+'), value: '75+' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'race',
      text: t('austin.survey.race.question'),
      color: colors.blueGrey,
      Component: CheckboxOptions,
      props: {
        options: [
          { text: t('austin.survey.race.white'), value: 'white' },
          { text: t('austin.survey.race.black'), value: 'black' },
          { text: t('austin.survey.race.asian'), value: 'asian' },
          { text: t('austin.survey.race.latinx'), value: 'latinx' },
          { text: t('austin.survey.race.islander'), value: 'islander' },
          { text: t('austin.survey.race.native'), value: 'native' },
          // { text: t('austin.survey.other'), value: 'other' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'income',
      text: t('austin.survey.income.question'),
      color: colors.blueGrey,
      Component: DropdownOptions,
      props: {
        options: [
          { text: t('austin.survey.-'), value: '', disabled: true },
          { text: t('austin.survey.income.20000-'), value: '20000-' },
          { text: t('austin.survey.income.20000-34999'), value: '20000-34999' },
          { text: t('austin.survey.income.35000-44999'), value: '35000-44999' },
          { text: t('austin.survey.income.45000-59999'), value: '45000-59999' },
          { text: t('austin.survey.income.60000-79999'), value: '60000-79999' },
          { text: t('austin.survey.income.80000-99999'), value: '80000-99999' },
          { text: t('austin.survey.income.100000-149999'), value: '100000-149999' },
          { text: t('austin.survey.income.150000+'), value: '150000+' },
          { text: t('austin.survey.other'), value: 'other' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'district',
      text: t('austin.survey.district.question'),
      color: colors.blueGrey,
      Component: DropdownOptions,
      props: {
        options: [
          { text: t('austin.survey.-'), value: '', disabled: true },
          { text: t('austin.survey.district.1'), value: '1' },
          { text: t('austin.survey.district.2'), value: '2' },
          { text: t('austin.survey.district.3'), value: '3' },
          { text: t('austin.survey.district.4'), value: '4' },
          { text: t('austin.survey.district.5'), value: '5' },
          { text: t('austin.survey.district.6'), value: '6' },
          { text: t('austin.survey.district.7'), value: '7' },
          { text: t('austin.survey.district.8'), value: '8' },
          { text: t('austin.survey.district.9'), value: '9' },
          { text: t('austin.survey.district.10'), value: '10' },
          { text: t('austin.survey.other'), value: 'other' },
        ],
      },
    },
    {
      id: 'home',
      text: t('austin.survey.home.question'),
      color: colors.blueGrey,
      Component: RadioOptions,
      props: {
        options: [
          { text: t('austin.survey.home.rent'), value: 'rent' },
          { text: t('austin.survey.home.own'), value: 'own' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'prediction-follow-up',
      name: 'email',
      text: 'Leave your email address here if you want to know how your prediction scored. It will not be used for any other purpose.',
      color: colors.blueGrey,
      Component: Text,
      props: {
        fullWidth: true,
      },
    },
    {
      id: 'share',
      text: t('austin.survey.share.question'),
      color: colors.blueGrey,
      Component: Text,
      props: {
        fullWidth: true,
        multiline: true,
      },
    },
  ];
}

function getHeaderText(t, slug) {
  if (slug === 'austin2020-prediction') {
    return 'Thank you for your prediction. To better understand how people with different backgrounds predict differently, please tell us a bit about yourself.  All questions are optional.';
  }
  return t('austin.survey.title');
}

function questions(t, consented, slug) {
  if (slug === 'austin2020-prediction') {
    return predictionSurvey(t);
  }
  const allQuestions = [
    {
      id: 'gender',
      text: t('austin.survey.gender.question'),
      color: colors.blueGrey,
      Component: CheckboxOptions,
      props: {
        options: [
          { text: t('austin.survey.gender.female'), value: 'female' },
          { text: t('austin.survey.gender.trans-female'), value: 'trans-female' },
          { text: t('austin.survey.gender.male'), value: 'male' },
          { text: t('austin.survey.gender.trans-male'), value: 'trans-male' },
          { text: t('austin.survey.gender.queer'), value: 'queer' },
          { text: t('austin.survey.gender.agender'), value: 'agender' },
          { text: t('austin.survey.gender.questioning'), value: 'questioning' },
          // { text: t('austin.survey.other'), value: 'other' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'age',
      text: t('austin.survey.age.question'),
      color: colors.blueGrey,
      Component: DropdownOptions,
      props: {
        options: [
          { text: t('austin.survey.-'), value: '', disabled: true },
          { text: t('austin.survey.age.18-'), value: '18-' },
          { text: t('austin.survey.age.18-24'), value: '18-24' },
          { text: t('austin.survey.age.25-34'), value: '25-34' },
          { text: t('austin.survey.age.35-44'), value: '35-44' },
          { text: t('austin.survey.age.45-54'), value: '45-54' },
          { text: t('austin.survey.age.55-64'), value: '55-64' },
          { text: t('austin.survey.age.65-74'), value: '65-74' },
          { text: t('austin.survey.age.75+'), value: '75+' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'race',
      text: t('austin.survey.race.question'),
      color: colors.blueGrey,
      Component: CheckboxOptions,
      props: {
        options: [
          { text: t('austin.survey.race.white'), value: 'white' },
          { text: t('austin.survey.race.black'), value: 'black' },
          { text: t('austin.survey.race.asian'), value: 'asian' },
          { text: t('austin.survey.race.latinx'), value: 'latinx' },
          { text: t('austin.survey.race.islander'), value: 'islander' },
          { text: t('austin.survey.race.native'), value: 'native' },
          // { text: t('austin.survey.other'), value: 'other' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'income',
      text: t('austin.survey.income.question'),
      color: colors.blueGrey,
      Component: DropdownOptions,
      props: {
        options: [
          { text: t('austin.survey.-'), value: '', disabled: true },
          { text: t('austin.survey.income.20000-'), value: '20000-' },
          { text: t('austin.survey.income.20000-34999'), value: '20000-34999' },
          { text: t('austin.survey.income.35000-44999'), value: '35000-44999' },
          { text: t('austin.survey.income.45000-59999'), value: '45000-59999' },
          { text: t('austin.survey.income.60000-79999'), value: '60000-79999' },
          { text: t('austin.survey.income.80000-99999'), value: '80000-99999' },
          { text: t('austin.survey.income.100000-149999'), value: '100000-149999' },
          { text: t('austin.survey.income.150000+'), value: '150000+' },
          { text: t('austin.survey.other'), value: 'other' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'district',
      text: t('austin.survey.district.question'),
      color: colors.blueGrey,
      Component: DropdownOptions,
      props: {
        options: [
          { text: t('austin.survey.-'), value: '', disabled: true },
          { text: t('austin.survey.district.1'), value: '1' },
          { text: t('austin.survey.district.2'), value: '2' },
          { text: t('austin.survey.district.3'), value: '3' },
          { text: t('austin.survey.district.4'), value: '4' },
          { text: t('austin.survey.district.5'), value: '5' },
          { text: t('austin.survey.district.6'), value: '6' },
          { text: t('austin.survey.district.7'), value: '7' },
          { text: t('austin.survey.district.8'), value: '8' },
          { text: t('austin.survey.district.9'), value: '9' },
          { text: t('austin.survey.district.10'), value: '10' },
          { text: t('austin.survey.other'), value: 'other' },
        ],
      },
    },
    {
      id: 'zipcode',
      text: t('austin.survey.zipcode.question'),
      color: colors.blueGrey,
      Component: Text,
      props: {},
    },
    {
      id: 'home',
      text: t('austin.survey.home.question'),
      color: colors.blueGrey,
      Component: RadioOptions,
      props: {
        options: [
          { text: t('austin.survey.home.rent'), value: 'rent' },
          { text: t('austin.survey.home.own'), value: 'own' },
          { text: t('austin.survey.undisclosed'), value: 'undisclosed' },
        ],
      },
    },
    {
      id: 'difficulty',
      text: t('austin.survey.difficulty.question'),
      color: colors.blueGrey,
      Component: MatrixTable,
      props: {
        scale: [
          { text: t('austin.survey.difficulty.very-easy'), value: 'very-easy' },
          { text: t('austin.survey.difficulty.somewhat-easy'), value: 'somewhat-easy' },
          { text: t('austin.survey.difficulty.neither'), value: 'neither' },
          { text: t('austin.survey.difficulty.somewhat-difficult'), value: 'somewhat-difficult' },
          { text: t('austin.survey.difficulty.very-difficult'), value: 'very-difficult' },
        ],
        statements: [
          { text: t('austin.survey.difficulty.introduction'), sid: 'introduction' },
          { text: t('austin.survey.difficulty.revenue'), sid: 'revenue' },
          { text: t('austin.survey.difficulty.expense'), sid: 'expense' },
        ],
      },
    },
    {
      id: 'statement',
      text: t('austin.survey.statement.question'),
      color: colors.blueGrey,
      Component: MatrixTable,
      props: {
        scale: [
          { text: t('austin.survey.statement.strongly-disagree'), value: 'strongly-disagree' },
          { text: t('austin.survey.statement.disagree'), value: 'disagree' },
          { text: t('austin.survey.statement.neither'), value: 'neither' },
          { text: t('austin.survey.statement.agree'), value: 'agree' },
          { text: t('austin.survey.statement.strongly-agree'), value: 'strongly-agree' },
        ],
        statements: [
          { text: t('austin.survey.statement.understanding'), sid: 'understanding' },
          { text: t('austin.survey.statement.input-usually'), sid: 'input-usually' },
          { text: t('austin.survey.statement.input-this'), sid: 'input-this' },
        ],
      },
    },
    {
      id: 'chanel',
      text: t('austin.survey.chanel.question'),
      color: colors.blueGrey,
      Component: DropdownOptions,
      props: {
        options: [
          { text: t('austin.survey.-'), value: '', disabled: true },
          { text: t('austin.survey.chanel.friend'), value: 'friend' },
          { text: t('austin.survey.chanel.organization'), value: 'organization' },
          { text: t('austin.survey.chanel.social-media'), value: 'social-media' },
          { text: t('austin.survey.chanel.email'), value: 'email' },
          { text: t('austin.survey.chanel.tv-radio'), value: 'tv-radio' },
          { text: t('austin.survey.chanel.website'), value: 'website' },
          { text: t('austin.survey.chanel.newspaper'), value: 'newspaper' },
          { text: t('austin.survey.other'), value: 'other' },
        ],
      },
    },
    {
      id: 'reason',
      text: t('austin.survey.reason.question'),
      color: colors.blueGrey,
      Component: Text,
      props: {
        fullWidth: true,
        multiline: true,
      },
    },
    {
      id: 'consideration',
      research: true,
      text: t('austin.survey.consideration.question'),
      color: colors.blueGrey,
      Component: Text,
      props: {
        fullWidth: true,
        multiline: true,
      },
    },
    {
      id: 'follow-up',
      name: 'email',
      research: true,
      text: t('austin.survey.follow-up.question'),
      color: colors.blueGrey,
      Component: Text,
      props: {
        fullWidth: true,
      },
    },
    {
      id: 'share',
      text: t('austin.survey.share.question'),
      color: colors.blueGrey,
      Component: Text,
      props: {
        fullWidth: true,
        multiline: true,
      },
    },
  ];
  if (consented) {
    return allQuestions;
  }
  return allQuestions.filter((question) => !question.research);
}

function AustinSurvey(props) {
  const { classes, nextUrl } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const code = useCode();
  const consented = useSelector((state) => state.user.consented);
  const userLanguage = useUserLanguage();
  const [responses] = useState(useSelector((state) => state.responses));
  const [response, setResponse] = useState(useSelector((state) => state.responses[STEP]));
  const [error, setError] = useState('');
  const slug = useSlug();
  const submit = useCallback(() => {
    request('/api/submit', 'POST', {
      code,
      lang_tag: userLanguage,
      slug,
      response: JSON.stringify({
        ...responses,
        [STEP]: response,
      }),
    }).then((response) => {
      if (!response.ok) {
        setError('error-submit');
      } else {
        history.push(nextUrl);
        dispatch(resetResponses());
        dispatch(init({}));
      }
    });
  }, [dispatch, code, userLanguage, setError, history, responses, response, nextUrl, slug]);

  const t = useT();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={1}>
        <form className={classes.form}>
          <Typography variant="h4" className={classes.title}>
            {getHeaderText(t, slug)}
          </Typography>
          {questions(t, consented, slug).map((question, index) => (
            <SurveyQuestion
              key={question.id}
              index={index + 1}
              response={response}
              step={STEP}
              setResponse={setResponse}
              {...question}
            />
          ))}
        </form>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button variant="contained" color="primary" style={{ margin: '16px auto' }} onClick={submit}>
            {t('submit')}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.error}>
          {error ? t(error) : null}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(AustinSurvey);
