import React, { useCallback, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Tooltip, Button, NoSsr, Menu, MenuItem,
} from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LANGUAGES_LABEL, LANGUAGES_CODES } from '../translations/languages';
import { optionsChange } from '../actions/options-action';
import { log } from '../api/client';
import { useT, useUserLanguage, useCode, useSlug } from './austin/hooks';


const styles = (theme) => ({
  language: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
});

function LanguageSettings(props) {
  const { classes } = props;
  const dispatch = useDispatch();
  const { search } = useLocation();
  const t = useT();
  const userLanguage = useUserLanguage();
  const code = useCode();
  const slug = useSlug();
  const [languageMenu, setLanguageMenu] = React.useState(null);
  const handleLanguageIconClick = useCallback((event) => {
    setLanguageMenu(event.currentTarget);
  }, [setLanguageMenu]);
  const handleLanguageMenuClose = useCallback((event) => {
    dispatch(optionsChange({ userLanguage: event.currentTarget.lang }));
    if (code) {
      log(code, 'CHANGE_LANG', event.currentTarget.lang, slug);
    }
    setLanguageMenu(null);
  }, [setLanguageMenu, code, dispatch, slug]);
  useEffect(() => {
    let locale = new URLSearchParams(search).get('locale');
    if (locale) {
      if (LANGUAGES_CODES.indexOf(locale) === -1) {
        [locale] = LANGUAGES_CODES;
      }
      dispatch(optionsChange({ userLanguage: locale }));
    }
  }, [search, dispatch]);
  useEffect(() => {
    if (code) {
      log(code, 'INITIAL_LANG', userLanguage, slug);
    }
  }, [code, userLanguage, slug]);
  return (
    <>
      <Tooltip title={t('change-language')} enterDelay={300}>
        <Button
          color="inherit"
          aria-owns={languageMenu ? 'language-menu' : undefined}
          aria-haspopup="true"
          aria-label={t('change-language')}
          onClick={handleLanguageIconClick}
          data-ga-event-category="AppBar"
          data-ga-event-action="language"
        >
          <LanguageIcon />
          <span className={classes.language}>
            {LANGUAGES_LABEL.filter((language) => language.code === userLanguage)[0].text}
          </span>
          <ExpandMoreIcon fontSize="small" />
        </Button>
      </Tooltip>
      <NoSsr>
        <Menu
          id="language-menu"
          anchorEl={languageMenu}
          open={Boolean(languageMenu)}
          onClose={handleLanguageMenuClose}
        >
          {LANGUAGES_LABEL.map((language) => (
            <MenuItem
              component="a"
              data-no-link="true"
              key={language.code}
              selected={userLanguage === language.code}
              onClick={handleLanguageMenuClose}
              lang={language.code}
            >
              {language.text}
            </MenuItem>
          ))}
        </Menu>
      </NoSsr>
    </>
  );
}

export default withStyles(styles)(LanguageSettings);
