import React from 'react';
import { Warning, ErrorRounded, Info } from '@material-ui/icons';
import { red, grey, amber } from '@material-ui/core/colors';

const categoryColor = [grey, amber, red];
const categoryIcon = [Info, Warning, ErrorRounded];

function ConsequenceIcon(props) {
  if (props.consequence == null) return null;
  const { level } = props.consequence;
  const Icon = categoryIcon[level];
  return <Icon style={{ color: categoryColor[level][600], verticalAlign: 'middle', marginRight: 5 }} />;
}


export default ConsequenceIcon;
