import { version } from '../../package.json';

export function request(uri, method, body, headers) {
  return fetch(uri, {
    method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  });
}

export function log(code, action, actionParams, slug) {
  const params = (typeof (actionParams) === 'object') ? { ...actionParams, version } : { version, param: actionParams };
  request('/api/log', 'POST', {
    code, action, action_params: JSON.stringify(params), slug
  });
}
