import {
  AppBar, Card, CardActions, CardHeader, Toolbar, Typography, Button,
} from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import BudgetIcon from './BudgetIcon';

import { AUSTIN_BASE_URL } from './austin/AustinMain';
import { LONG_BEACH_BASE_URL } from './longBeach/LongBeachMain';

const styles = (theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    flexGrow: 1,
    flex: '1 0 100%',
  },
  text: {
    marginTop: theme.spacing(8),
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(${theme.palette.primary.main}33, ${theme.palette.primary.main}EE 50%), url("/front-image.jpeg")`,
    backgroundSize: 'cover',
    height: '60vw',
    maxHeight: '80vh',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
  },
  headerTitle: {
    flexGrow: 1,
  },
  title: {
    color: theme.palette.common.white,
    padding: theme.spacing(1),
  },
  sectionHeader: {
    marginTop: theme.spacing(6),
    color: theme.palette.primary.main,
  },
  h5: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(1),
    maxWidth: 600,
    textAlign: 'center',
  },
  content: {
    height: '100%',
  },
  cardsContent: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      padding: 0,
      paddingTop: 15,
    },
  },
  card: {
    minWidth: 200,
    maxWidth: 300,
    margin: theme.spacing(3),
    textAlign: 'center',
  },
  action: {
    flexDirection: 'column-reverse',
  },
});

const LandingPage = ({ classes }) => (
  <div className={classes.main}>
    <AppBar position="fixed" color="inherit">
      <Toolbar>
        <BudgetIcon />
        <Typography variant="h6" color="primary" className={classes.headerTitle}>
        Stanford City Budgeting Platform
        </Typography>
      </Toolbar>
    </AppBar>

    <div className={classes.root}>
      <div className={classes.text}>
        <Typography
          variant="h3"
          align="center"
          component="h1"
          className={classes.title}
        >
        Stanford City Budgeting Platform
        </Typography>
        <Typography
          variant="h5"
          align="center"
          component="h5"
          className={classes.title}
        >
        A platform for residents to engage in city budgeting process
        </Typography>
      </div>
      <Typography variant="h4" align="center" component="h4" className={classes.sectionHeader}>Demonstrations/Inactive</Typography>
      <div className={classes.cardsContent}>
        <Card className={classes.card}>
          <CardHeader title="Durham" />
          <CardActions classes={{ root: classes.action }}>
            <Button size="small" color="primary" href="/budget?demo=toplevel">
            See in action
            </Button>
          </CardActions>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="Durham - Nested" />
          <CardActions classes={{ root: classes.action }}>
            <Button size="small" color="primary" href="/budget?demo=nested">
            See in action
            </Button>
          </CardActions>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="Austin" />
          <CardActions classes={{ root: classes.action }}>
            <Button size="small" color="primary" href={AUSTIN_BASE_URL}>
            See in action
            </Button>
          </CardActions>
        </Card>
        <Card className={classes.card}>
          <CardHeader title="Long Beach" />
          <CardActions classes={{ root: classes.action }}>
            <Button size="small" color="primary" href={LONG_BEACH_BASE_URL}>
              See in action
            </Button>
          </CardActions>
        </Card>
      </div>
    </div>
  </div>
);

export default withRouter(withStyles(styles, { withTheme: true })(LandingPage));
