import React from 'react';
import { range } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  icon: {
    width: 32,
    padding: theme.spacing(0, 1),
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main,
  },
});

function BudgetIcon(props) {
  return (
    <svg viewBox="0 0 200 200" x="0" y="0" className={props.classes.icon}>
      <defs>
        <mask id="quarter-mask" maskUnits="userSpaceOnUse" x="0" y="0" width="200" height="200">
          <rect x="0" y="0" width="200" height="200" fill="white" />
          <rect x="100" y="0" width="100" height="100" fill="black" />
        </mask>
      </defs>
      <g>
        <text fontWeight="bold" textAnchor="middle" fontFamily="'Trebuchet MS', Gadget, sans-serif" fontSize="140" y="150" x="100">$</text>
        <ellipse mask="url(#quarter-mask)" ry="90" rx="90" cy="100" cx="100" strokeWidth="20" fill="none" />
        {
          range(-3, 7).map((i) => <circle key={i} cx={100 + Math.cos((i * Math.PI) / 6) * 90} cy={100 + Math.sin((i * Math.PI) / 6) * 90} r="10" />)
        }
      </g>
    </svg>
  );
}

export default withStyles(styles)(BudgetIcon);
