import React, { useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper, Grid, Divider, colors, Typography, TextField,
} from '@material-ui/core';
import {
  PieChart, Pie, Cell, ResponsiveContainer, Tooltip,
} from 'recharts';
import cx from 'clsx';
import { Lock } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { request, log } from '../../api/client';
import Revenue from './pages/Revenue';
import formatters from '../../util/formatters';
import palettes from '../../util/palette';
import { updateStepResponse } from '../../actions/response-action';
import chartData from './chart-data/revenue.json';
import AustinNextBar from './AustinNextBar';
import { RadioOptions } from '../SurveyQuestion';
import { useSlug, useT, useCode, useUserLanguage, useResponse } from './hooks';

const STEP = 'revenue';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    fontFamily: 'Roboto Condensed',
    textAlign: 'left',
    border: `1px solid ${theme.palette.divider}`,
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
  example: {
    fontSize: '0.9rem',
    fontFamily: 'Roboto Condensed',
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  questionContainer: {
    margin: '16px 0',
    textAlign: 'left',
  },
  questionText: {
    fontSize: '1.2rem',
    borderLeft: `16px solid ${colors.grey[400]}`,
    paddingLeft: 8,
    fontFamily: 'Roboto Condensed',
  },
  subquestionContainer: {
    margin: '0 16px',
    padding: '0 16px',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
    },
  },
  option: {
    flex: '0 0 25%',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 100%',
    },
  },
  radiogroup: {
    width: '100%',
  },
  exampleContainer: {
    border: '1px solid rgba(0, 0, 0, .125)',
    padding: 8,
    margin: '8px 0',
  },
  missing: {
    border: `2px solid ${colors.red[200]}`,
  },
});

const requiredInput = ['taxrate', 'animal', 'health', 'ems', 'fire', 'aquatic', 'program', 'golf', 'rental', 'zoning'];

function isIncomplete(response) {
  return requiredInput.some((key) => !response[key]);
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, name, locked,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.3;
  const lockRadius = innerRadius + (outerRadius - innerRadius) * 0.7;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const lockx = cx + lockRadius * Math.cos(-midAngle * RADIAN) - 8;
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const locky = cy + lockRadius * Math.sin(-midAngle * RADIAN) - 12;
  const size = 14;
  return (
    <g>
      <text x={x} y={y} fill="black" fontSize={size} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        <tspan x={x} dy={0}>{name}</tspan>
      </text>
      {locked ? (
        <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" transform={`translate(${lockx},${locky})`} fill="#333" />
      ) : null}
    </g>
  );
};

function tooltipFormatter(value) {
  return `$${formatters.kmb(value)}`;
}

function RevenueChart() {
  const t = useT();
  const code = useCode();
  const slug = useSlug();
  const data = chartData.map((entry, index) => (
    { ...entry, name: t(entry['full-key']), colors: palettes[(index + 2) % palettes.length] }
  ));
  const [interactions, setInteractions] = useState([]);
  const onHover = useCallback(({ payload }) => {
    interactions.push(payload.key);
  }, [interactions]);
  const onMouseLeave = useCallback(() => {
    if (interactions.length > 0) {
      log(code, 'CHART_HOVER', { step: STEP, interactions }, slug);
      setInteractions([]);
    }
  }, [code, interactions, setInteractions, slug]);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ lineHeight: '1.5rem' }}>
          <ResponsiveContainer aspect={1.2} width="100%" maxHeight={300}>
            <PieChart onClick={() => { }} onMouseLeave={onMouseLeave}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine
                label={renderCustomizedLabel}
                outerRadius="60%"
                fill="#8884d8"
                dataKey="value"
                onMouseEnter={onHover}
              >
                {
                  data.map((entry) => (
                    <Cell
                      key={`cell-${entry.key}`}
                      fill={
                        entry.locked ? entry.colors[100] : entry.colors[400]
                      }
                    />
                  ))
                }
              </Pie>
              <Tooltip formatter={tooltipFormatter} />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Lock style={{ padding: 8 }} />
        {t('austin.revenue.notadjustable')}
      </div>
    </>
  );
}

function SurveyHeader(props) {
  const {
    classes, color, text,
  } = props;
  return (
    <Grid item xs={12} className={classes.questionContainer}>
      <Typography variant="h4" className={classes.questionText} style={{ borderLeftColor: color[400] }}>
        {text}
      </Typography>
    </Grid>
  );
}

function CategoryIncrease(props) {
  const {
    response, setResponse, id, title, example, classes, step, className,
  } = props;
  const t = useT();
  return (
    <Grid item xs={12} className={cx(classes.exampleContainer, className)}>
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>
      <div className={classes.example}>
        {example}
      </div>
      <RadioOptions
        classes={classes}
        response={response}
        setResponse={setResponse}
        step={step}
        id={id}
        name={title}
        options={[
          { text: t('austin.revenue.no-change'), value: 'no-change' },
          { text: t('austin.revenue.moderate-increase'), value: 'moderate-increase' },
          { text: t('austin.revenue.significant-increase'), value: 'significant-increase' },
          { text: t('no-opinion'), value: 'no-opinion' },
        ]}
      />
    </Grid>
  );
}

function Comment(props) {
  const {
    response, setResponse, id, label,
  } = props;
  const onChange = useCallback((event) => {
    setResponse({ ...response, [id]: event.target.value });
  }, [response, setResponse, id]);
  return (
    <TextField
      label={label}
      fullWidth
      multiline
      margin="normal"
      variant="outlined"
      value={response[id]}
      onChange={onChange}
    />
  );
}

function SurveyQuestion(props) {
  const {
    classes, color, text, response, setResponse, questions, attemptNext,
  } = props;
  return (
    <>
      <Divider />
      <SurveyHeader classes={classes} color={color} text={text} />
      <Grid item xs={12} className={classes.subquestionContainer}>
        <Grid container>
          {questions.map((question) => {
            const Component = question.component;
            return (
              <Component
                className={attemptNext && !response[question.id] ? classes.missing : null}
                key={question.id}
                classes={classes}
                id={question.id}
                step={STEP}
                response={response}
                setResponse={setResponse}
                {...question.props}
              />
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}


function translatedQuestions(t, userLanguage) {
  if (userLanguage === 'rc') {
    return [
      {
        id: 'comment',
        text: t('austin.revenue.comment'),
        color: colors.blueGrey,
        questions: [
          {
            id: 'comment',
            component: Comment,
            props: {
              // label: t('austin.revenue.comment'),
            },
          },
        ],
      },
    ];
  }
  return [
    {
      id: 'taxrate',
      text: t('austin.revenue.taxrate'),
      color: colors.blueGrey,
      questions: [
        {
          id: 'taxrate',
          component: RadioOptions,
          props: {
            options: [
              { text: t('yes'), value: 'yes' },
              { text: t('no'), value: 'no' },
              { text: t('no-opinion'), value: 'no-opinion' },
            ],
            name: 'tax rate election',
            id: 'taxrate',
          },
        },
      ],
    },
    {
      id: 'fee-increase',
      text: t('austin.revenue.fee-increase'),
      color: colors.teal,
      questions: [{
        id: 'animal',
        component: CategoryIncrease,
        props: {
          title: t('austin.revenue.animal'),
          example: t('austin.revenue.animalexample'),
        },
      },
      {
        id: 'health',
        component: CategoryIncrease,
        props: {
          title: t('austin.revenue.health'),
          example: t('austin.revenue.healthexample'),
        },
      },
      {
        id: 'ems',
        component: CategoryIncrease,
        props: {
          title: t('austin.revenue.ems'),
          example: t('austin.revenue.emsexample'),
        },
      },
      {
        id: 'fire',
        component: CategoryIncrease,
        props: {
          title: t('austin.revenue.fire'),
          example: t('austin.revenue.fireexample'),
        },
      },
      {
        id: 'aquatic',
        component: CategoryIncrease,
        props: {
          title: t('austin.revenue.aquatic'),
          example: t('austin.revenue.aquaticexample'),
        },
      },
      {
        id: 'program',
        component: CategoryIncrease,
        props: {
          title: t('austin.revenue.program'),
          example: t('austin.revenue.programexample'),
        },
      },
      {
        id: 'golf',
        component: CategoryIncrease,
        props: {
          title: t('austin.revenue.golf'),
          example: t('austin.revenue.golfexample'),
        },
      },
      {
        id: 'rental',
        component: CategoryIncrease,
        props: {
          title: t('austin.revenue.rental'),
          example: t('austin.revenue.rentalexample'),
        },
      },
      {
        id: 'zoning',
        component: CategoryIncrease,
        props: {
          title: t('austin.revenue.zoning'),
          example: t('austin.revenue.zoningexample'),
        },
      },
      {
        id: 'comment',
        component: Comment,
        props: {
          label: t('austin.revenue.comment'),
        },
      },
      ],
    },
  ];
}

function AustinRevenue(props) {
  const { classes, nextUrl } = props;
  const t = useT();
  const userLanguage = useUserLanguage();
  const questions = translatedQuestions(t, userLanguage);
  const history = useHistory();
  const dispatch = useDispatch();
  const code = useCode();
  const slug = useSlug();
  const [error, setError] = useState('');
  const [attemptNext, setAttemptNext] = useState(false);
  const [response, setResponse] = useState(useResponse(STEP));
  const next = useCallback(
    () => {
      if (userLanguage !== 'rc' && isIncomplete(response)) {
        setAttemptNext(true);
        return;
      }
      dispatch(updateStepResponse({ step: STEP, response }));
      request('/api/save', 'POST', {
        code, step: STEP, lang_tag: userLanguage, response: JSON.stringify(response), slug,
      }).then((response) => {
        if (!response.ok) {
          setError('error-next');
        } else {
          history.push(nextUrl);
        }
      });
    },
    [dispatch, code, userLanguage, setError, history, response, nextUrl, slug],
  );

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.item}>
          <Revenue />
        </Grid>
        <Divider style={{ margin: '10px 0' }} />
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.item}>
              <RevenueChart />
              {questions.map((question) => (
                <SurveyQuestion
                  key={question.id}
                  classes={classes}
                  response={response}
                  setResponse={setResponse}
                  attemptNext={attemptNext}
                  {...question}
                />
              ))}
            </Grid>
          </Grid>
          <AustinNextBar error={isIncomplete(response) ? 'completion-prompt' : error} onClick={next} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(AustinRevenue);
