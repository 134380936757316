import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Route, useRouteMatch } from 'react-router-dom';
import CityBudget from '../views/CityBudgetView';
import BudgetSummary from '../views/BudgetSummaryView';
import Item from '../views/ItemView';
import { budgetMockInit } from '../actions/budget-action';
import mockTopLevelData from '../mock.json';
import mockNestedData from '../mockNested.json';

const mockDataPath = {
  toplevel: mockTopLevelData,
  nested: mockNestedData,
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(2, 4),
  },
});

function AppContent(props) {
  const { path } = useRouteMatch();
  const { search } = props.location;
  const { init } = props;
  useEffect(() => {
    const param = new URLSearchParams(search);
    const dataset = param.has('demo') ? param.get('demo') : 'toplevel';
    if (dataset in mockDataPath) {
      init(mockDataPath[dataset]);
    }
  }, [search, init]);
  return (
    <div className={props.classes.root}>
      <Route exact path={path} component={CityBudget} />
      <Route exact path={`${path}/summary`} component={BudgetSummary} />
      <Route exact path={`${path}/item/:itemId`} component={Item} />
    </div>
  );
}

export default connect(() => ({}), {
  init: budgetMockInit,
})(withStyles(styles)(AppContent));
