import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';

import * as actions from '../actions/budget-action';
import util from '../util/budgetUtil';

const initialState = {
  budgetDetails: {},
  proposal: {},
};


const budget = createReducer(initialState, {
  [actions.budgetMockInit]: (__, { payload: { title, amount, items } }) => {
    const budgetDetails = {
      title,
      amount,
      items: _.keyBy(items.map((item) => ({
        ...item,
        subitems: item.subitems && _.keyBy(item.subitems.map((si) => ({
          ...si, readonly: si.name === 'Other', min: si.suggested * 0.8, max: si.suggested * 1.2,
        })), 'id'),
      })), 'id'),
    };
    const proposal = {
      amount: _.round(_.sum(items.map((item) => item.suggested))),
      items: _.mapValues(budgetDetails.items, util.mapToProposalItem),
    };
    return {
      budgetDetails,
      proposal,
    };
  },
  [actions.updateItemComment]: (state, { payload }) => {
    state.proposal.items[payload.id].comment = payload.value;
  },
  [actions.resetProposal]: (state) => {
    const { budgetDetails } = state;
    state.proposal = {
      amount: _.round(_.sum(_.values(budgetDetails.items).map((item) => item.suggested))),
      items: _.mapValues(budgetDetails.items, util.mapToProposalItem),
    };
  },
  [actions.resetItemProposal]: (state, { payload }) => {
    const { proposal, budgetDetails } = state;
    proposal.items[payload] = util.mapToProposalItem(budgetDetails.items[payload]);
    proposal.amount = _.round(_.sum(_.values(proposal.items).map((item) => item.amount)));
  },
  [actions.updateItemAmount]: (state, { payload }) => {
    const { proposal, budgetDetails } = state;
    const { min, max, suggested } = budgetDetails.items[payload.id];
    const item = proposal.items[payload.id];
    const amount = payload.delta ? item.amount + payload.delta : payload.value;
    const bounded = Math.max(min, Math.min(max, amount));
    proposal.items[payload.id].amount = bounded;
    proposal.items[payload.id].diff = bounded - suggested;
    proposal.items[payload.id].consequence = util.getConsequence(bounded, item.flags);
    proposal.amount = _.round(_.sum(_.values(proposal.items).map((item) => item.amount)));
  },
  [actions.updateSubitemAmount]: (state, { payload }) => {
    const { proposal, budgetDetails } = state;
    const {
      pid, id, value, delta,
    } = payload;
    const proposed = proposal.items[pid];
    const oldSubAmount = proposed.subitems[id].amount;
    const item = budgetDetails.items[pid];
    const subitem = item.subitems[id];
    let toAdd = delta || (value - oldSubAmount);
    if (toAdd > 0) {
      toAdd = Math.min(toAdd, subitem.max - oldSubAmount, item.max - proposed.amount);
    } else {
      toAdd = Math.max(toAdd, subitem.min - oldSubAmount, item.min - proposed.amount);
    }
    const newSubAmount = oldSubAmount + toAdd;
    const newAmount = proposed.amount + toAdd;
    proposed.subitems[id].amount = newSubAmount;
    proposed.subitems[id].diff = newSubAmount - subitem.suggested;
    proposed.subitems[id].consequence = util.getConsequence(newSubAmount, subitem.flags);
    proposal.items[pid].amount = newAmount;
    proposal.items[pid].diff = newAmount - item.suggested;
    proposal.items[pid].consequence = util.getConsequence(newAmount, item.flags);
    proposal.amount = _.round(_.sum(_.values(proposal.items).map((item) => item.amount)));
  },
  [actions.balanceBudget]: (state) => {
    const { budgetDetails, proposal } = state;
    state.proposal = util.scaleAll(budgetDetails, proposal);
  },
});

export default budget;
