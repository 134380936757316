import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar, Toolbar, Typography, IconButton,
} from '@material-ui/core';
import Help from '@material-ui/icons/Help';
import { connect } from 'react-redux';
import { startIntro } from '../actions/introjs-action';
import BudgetIcon from '../components/BudgetIcon';
import LanguageSettings from '../components/LanguageSettings';

const styles = () => ({
  title: {
    flexGrow: 1,
  },
});

function AppHeader(props) {
  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <BudgetIcon />
        <Typography variant="h6" color="primary" className={props.classes.title}>
          {props.title}
        </Typography>
        <div className="help-button">
          <IconButton
            onClick={() => props.startIntro()}
            color="inherit"
          >
            <Help />
          </IconButton>
          <LanguageSettings />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default connect((state) => ({
  title: state.budget.budgetDetails.title,
}), { startIntro })(withStyles(styles)(AppHeader));
