import React from 'react';

function ComparisonBar(props) {
  const {
    oldVal, newVal, maxVal, color,
  } = props;
  return (
    <svg width="100%" height="16" style={{ overflow: 'hidden' }}>
      <g>
        <rect x="0" y="0" width={`${(oldVal * 100) / maxVal}%`} height="8" stroke="none" strokeWidth="0" fill={color[100]} />
        <rect x="0" y="8" width={`${(newVal * 100) / maxVal}%`} height="8" stroke="none" strokeWidth="0" fill={color[600]} />
      </g>
    </svg>
  );
}


export default ComparisonBar;
