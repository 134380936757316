/* eslint-disable no-console */
import { createReducer } from '@reduxjs/toolkit';
import memoize from '@material-ui/system/memoize';

import * as actions from '../actions/options-action';

const req = require.context('../translations', false, /translations.*\.json$/);

function mapTranslations(req, ext) {
  const translations = {};
  req.keys().forEach((filename) => {
    const match = filename.match(new RegExp(`-([a-z]{2}).${ext}$`));
    translations[match[1]] = req(filename);
  });
  return translations;
}

const translations = mapTranslations(req, 'json');

function getPath(obj, path) {
  if (!path || typeof path !== 'string') {
    return null;
  }

  return path.split('.').reduce((acc, item) => (acc && acc[item] ? acc[item] : null), obj);
}


const warnOnce = {};

export const getT = memoize((userLanguage) => (key, options = {}) => {
  const { ignoreWarning = false } = options;
  const wordings = translations[userLanguage];

  if (!wordings) {
    console.error(`Missing language: ${userLanguage}.`);
    return '…';
  }

  const translation = getPath(wordings, key);

  if (!translation) {
    const fullKey = `${userLanguage}:${key}`;
    // No warnings in CI env
    if (!ignoreWarning && !warnOnce[fullKey] && typeof window !== 'undefined') {
      console.error(`Missing translation for ${fullKey}.`);
      warnOnce[fullKey] = true;
    }
    return getPath(translations.en, key);
  }

  return translation;
});

const initialState = {
  userLanguage: 'en',
  t: getT('en'),
};

const optionsReducer = createReducer(initialState, {
  [actions.optionsChange]: (state, action) => {
    state.userLanguage = action.payload.userLanguage || state.userLanguage;
    state.t = getT(state.userLanguage);
  },
});

export default optionsReducer;
