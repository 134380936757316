import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage/session';

import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import createRootReducer from './reducers';
import { getT } from './reducers/options-reducer'; // TODO: refactor

const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'options', 'responses'],
  transforms: [createTransform(
    (inState) => ({ ...inState, t: null }),
    (outState) => ({ ...outState, t: getT(outState.userLanguage) }),
    { whitelist: ['options'] },
  ),
  ],
};

const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

const store = configureStore({
  reducer: persistedReducer,
  middleware: [...getDefaultMiddleware({ serializableCheck: false }), routerMiddleware(history)],
  devTools: process.env.NODE_ENV !== 'production',
});

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Route component={App} />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
