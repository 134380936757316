import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CityBudgetItem from './CityBudgetItem';
import colors from '../util/palette';

const styles = (theme) => ({
  toolbar: {
    display: 'flex',
    fontSize: '0.85rem',
    flexDirection: 'row-reverse',
    color: theme.palette.text.link,
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    margin: '0 5px',
    userSelect: 'none',
  },
});

class CityBudgetDetails extends Component {
  state = {
    expanded: false,
    nupdate: 0,
  }

  onToggle(expanded) {
    return () => {
      this.setState((state) => ({ expanded, nupdate: state.nupdate + 1 }));
    };
  }

  render() {
    const { classes, budgetDetails, proposal } = this.props;
    const maxVal = Math.max(
      ..._.values(budgetDetails.items).map((item) => item.suggested),
      ..._.values(proposal.items).map((item) => item.amount),
    );
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.toolbar}>
          <div className={classes.link} onClick={this.onToggle(false)}>Collapse All</div>
          /
          <div className={classes.link} onClick={this.onToggle(true)}>Expand All</div>
        </Grid>
        {
          _.values(budgetDetails.items).map((item, idx) => (
            <Grid item xs={12} key={item.id}>
              <CityBudgetItem
                {...this.state}
                idx={idx}
                details={item}
                proposed={proposal.items[item.id]}
                maxVal={maxVal}
                color={colors[item.id % colors.length]}
              />
            </Grid>
          ))
        }
      </Grid>
    );
  }
}

export default connect((state) => {
  const { budgetDetails, proposal } = state.budget;
  return {
    budgetDetails,
    proposal,
  };
})(withStyles(styles)(CityBudgetDetails));
