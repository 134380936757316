import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';
import {
  Remove, ArrowUpward, ArrowDownward,
} from '@material-ui/icons';
import {
  red, grey, green,
} from '@material-ui/core/colors';

import formatters from '../util/formatters';
import ComparisonBar from './ComparisonBar';
import ConsequenceIcon from './ConsequenceIcon';


const styles = () => ({
  labelTitle: {
    fontWeight: 700,
    fontSize: '1.1rem',
    fontFamily: 'Roboto Condensed',
    marginRight: 10,
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  label: {
    fontFamily: 'Roboto Condensed',
    whiteSpace: 'nowrap',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
});

function DiffIcon(props) {
  let Icon = Remove;
  let iconColor = grey;
  if (props.diff > 0) {
    Icon = ArrowUpward;
    iconColor = green;
  } else if (props.diff < 0) {
    Icon = ArrowDownward;
    iconColor = red;
  }
  return <Icon style={{ color: iconColor[600], verticalAlign: 'middle' }} />;
}

function CityBudgetItemSummary(props) {
  const {
    classes, proposed, details, maxVal, color, diffPct,
  } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={8} sm={5} className={classes.container}>
        <div className={classes.labelTitle} title={details.name}>
          <ConsequenceIcon consequence={proposed.consequence} />
          {details.name}
        </div>
        <Hidden xsDown>
          <div className={classes.label}>{formatters.kmb(proposed.amount)}</div>
        </Hidden>
      </Grid>
      <Grid item xs={4} sm={7} className={classes.container}>
        <Hidden xsDown>
          <ComparisonBar
            oldVal={details.suggested}
            newVal={proposed.amount}
            maxVal={maxVal}
            color={color}
          />
        </Hidden>
        <DiffIcon diff={diffPct} />
        <div className={classes.label}>{`${formatters.kmb(diffPct)}%`}</div>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(CityBudgetItemSummary);
