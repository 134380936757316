import React, { useCallback, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Overview from './pages/Overview';
import { request } from '../../api/client';
import AustinNextBar from './AustinNextBar';
import {useCode, useUserLanguage, useSlug, useResponse} from './hooks';

const STEP = 'overview';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    fontFamily: 'Roboto',
    textAlign: 'left',
    border: `1px solid ${theme.palette.divider}`,
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
});

function AustinOverview(props) {
  const { classes, nextUrl } = props;
  const userLanguage = useUserLanguage();
  const slug = useSlug();
  const history = useHistory();
  const code = useCode();
  const response = useResponse(STEP);
  const [error, setError] = useState('');
  const next = useCallback(
    () => {
      request('/api/save', 'POST', {
        code, step: STEP, lang_tag: userLanguage, response: JSON.stringify(response), slug
      }).then((response) => {
        if (!response.ok) {
          response.json().then((body) => {
            setError(body.message || 'error-next');
          }).catch(() => {
            setError('error-next');
          });
        } else {
          history.push(nextUrl);
        }
      });
    },
    [code, userLanguage, setError, history, response, nextUrl, slug],
  );
  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Overview />
        </Grid>
        <AustinNextBar error={error} disabled={false} disabledPrompt="" onClick={next} />
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(AustinOverview);
