import _ from 'lodash';
import data from './mock.json';

function getRandom(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const count = 100;

function getProposal(min, max) {
  return _.fill(Array(count), 0).map(() => getRandom((5 * min + max) / 6, (5 * max + min) / 6));
}

const summary = {
  ...data,
  items: data.items.map((item) => ({ ...item, proposed: getProposal(item.min, item.max) })),
};

export default summary;
