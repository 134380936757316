import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl, TextField, InputLabel, Select, MenuItem,
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
} from '@material-ui/core';


const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    maxWidth: 720,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  formControl: {
    padding: theme.spacing(1, 0),
  },
});

const SurveyDialog = (props) => {
  const { classes, ...others } = props;
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [zipcode, setZipCode] = useState('');
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
      aria-labelledby="survey-dialog-title"
      {...others}
    >
      <DialogTitle id="survey-dialog-title">Additional Information</DialogTitle>
      <DialogContent>
        Thanks for providing your view on the city budget.
        Before you submit, please tell us a little bit about yourself.
        <form className={classes.form}>
          <TextField
            id="zipcode"
            label="ZIP Code"
            className={classes.formControl}
            value={zipcode}
            onChange={(evt) => setZipCode(evt.target.value)}
            margin="normal"
          />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="age-group">Age Group</InputLabel>
            <Select
              value={age}
              onChange={(evt) => setAge(evt.target.value)}
              inputProps={{
                name: 'age-group',
                id: 'age-group',
              }}
            >
              <MenuItem value=""><em>Please Select</em></MenuItem>
              <MenuItem value="18-">Under 18</MenuItem>
              <MenuItem value="18-29">18-29</MenuItem>
              <MenuItem value="30-49">30-49</MenuItem>
              <MenuItem value="50-69">50-69</MenuItem>
              <MenuItem value="70+">Over 70</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="gender">Gender</InputLabel>
            <Select
              value={gender}
              onChange={(evt) => setGender(evt.target.value)}
              inputProps={{
                name: 'gender',
                id: 'gender',
              }}
            >
              <MenuItem value=""><em>Please Select</em></MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary">
            Cancel
        </Button>
        <Button disabled={!(zipcode && age && gender)} onClick={props.onSubmit} color="primary">
            Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(SurveyDialog);
