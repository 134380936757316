import React, { Component } from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import formatters from '../util/formatters';
import colors from '../util/palette';

const styles = (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1],
    fontSize: '0.8rem',
  },
  svgCat: {
    fontSize: '0.8rem',
  },
  svgText: {
    fontSize: '0.8rem',
  },
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  legendItem: {
    display: 'flex',
    fontSize: '0.8rem',
    margin: 2,
  },
});

function toCompositeChartData(budget, proposal) {
  const maxSum = _.max([budget.amount, proposal.amount]);
  const items = [];
  let ssum = 0;
  let psum = 0;
  const svgWidth = Math.min(document.body.clientWidth, 900) - 112;
  _.forOwn(budget.items, (item) => {
    const samount = item.suggested;
    const swidth = (samount * 100) / maxSum;
    const pamount = proposal.items[item.id].amount;
    const pwidth = (pamount * 100) / maxSum;
    items.push({
      id: item.id,
      name: item.name,
      color: colors[item.id % colors.length],
      suggested: {
        start: `${ssum.toFixed(1)}%`,
        amount: samount,
        width: `${swidth.toFixed(1)}%`,
        end: `${(ssum + swidth).toFixed(1)}%`,
        showText: svgWidth * swidth >= 4000,
      },
      proposed: {
        start: `${psum.toFixed(1)}%`,
        amount: pamount,
        width: `${pwidth.toFixed(1)}%`,
        end: `${(psum + pwidth).toFixed(1)}%`,
        showText: svgWidth * pwidth >= 4000,
      },
    });
    ssum += swidth;
    psum += pwidth;
  });
  return items;
}

function getHighlightBlock(chartData, classes, hover, hoverIdx, h) {
  if (!hover) return null;
  const isTop = hover === 'top';
  const { start } = chartData[hoverIdx][isTop ? 'suggested' : 'proposed'];
  const show = isTop ? 'proposed' : 'suggested';
  const y = isTop ? 3 * h - 5 : h + 5;

  return (
    <g>
      <rect x={start} y={y} width={chartData[hoverIdx][show].width} height={h} stroke="none" strokeWidth="0" fill={chartData[hoverIdx].color[isTop ? 600 : 400]} filter="url(#f1)" />
      {
            chartData[hoverIdx][show].showText
              ? <text x={start} style={{ transform: `translateX(${chartData[hoverIdx][show].width})` }} y={y + h - 2} className={classes.svgText} fill="white" textAnchor="end">{formatters.kmb(chartData[hoverIdx][show].amount)}</text>
              : null
        }
    </g>
  );
}

function getTooltip(item) {
  const { name, suggested, proposed } = item;
  return (
    <>
      <div style={{ fontWeight: 700 }}>{name}</div>
      <div>
        <dt>Suggested</dt>
        <dd>{formatters.kmb(suggested.amount)}</dd>
        <dt>You proposed</dt>
        <dd>{formatters.kmb(proposed.amount)}</dd>
      </div>
    </>
  );
}

class CityBudgetSummaryChart extends Component {
    state = {
      hover: null,
      hoverIdx: null,
    }

    onHover(hoverIdx, hover) {
      return () => {
        this.setState({ hover, hoverIdx });
      };
    }

    onResize = () => {
      this.forceUpdate();
    }

    componentDidMount() {
      window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
    }

    render() {
      const {
        budgetText, proposalText, classes, budget, proposal,
      } = this.props;
      const { hover, hoverIdx } = this.state;
      const chartData = toCompositeChartData(budget, proposal);
      const h = 15;
      return (
        <div>
          <svg width="100%" height={4 * h + 10}>
            <defs>
              <filter id="f1" x="0" y="0" width="200%" height="200%">
                <feOffset result="offOut" in="SourceAlpha" dx="0" dy="2" />
                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs>
            {chartData.map((item, i) => {
              const {
                id, color, suggested, proposed,
              } = item;
              const topShade = !hover || (hover === 'top' && hoverIdx === i) ? 400 : 100;
              const bottomShade = !hover || (hover === 'bottom' && hoverIdx === i) ? 600 : 200;
              const lineShade = hover ? 100 : 600;
              return (
                <g key={id}>
                  <Tooltip placement="top" title={getTooltip(item)} classes={{ tooltip: classes.tooltip }} onOpen={this.onHover(i, 'top')} onClose={this.onHover()}>
                    <g>
                      <rect x={suggested.start} y={h} width={suggested.width} height={h} stroke="none" strokeWidth="0" fill={color[topShade]} />
                      {
                                        suggested.showText
                                          ? <text x={suggested.end} y={2 * h - 2} className={classes.svgText} fill="white" textAnchor="end">{formatters.kmb(suggested.amount)}</text>
                                          : null
                                    }
                    </g>
                  </Tooltip>
                  <Tooltip placement="bottom" title={getTooltip(item)} classes={{ tooltip: classes.tooltip }} onOpen={this.onHover(i, 'bottom')} onClose={this.onHover()}>
                    <g>
                      <rect x={proposed.start} y={3 * h} width={proposed.width} height={h} stroke="none" strokeWidth="0" fill={color[bottomShade]} />
                      {
                                        proposed.showText
                                          ? <text x={proposed.end} y={4 * h - 2} className={classes.svgText} fill="white" textAnchor="end">{formatters.kmb(proposed.amount)}</text>
                                          : null
                                    }
                    </g>
                  </Tooltip>
                  <line strokeDasharray="2, 2" x1={suggested.end} x2={proposed.end} y1={2 * h} y2={3 * h} stroke={grey[lineShade]} strokeWidth="2" />
                </g>
              );
            })}
            <text x={0} y={h - 4} className={classes.svgText} textAnchor="start">{budgetText}</text>
            <text x={0} y={3 * h - 2} className={classes.svgText} textAnchor="start">{proposalText}</text>
            {getHighlightBlock(chartData, classes, hover, hoverIdx, h)}
          </svg>
          <div className={classes.legend}>
            {chartData.map((item) => (
              <div className={classes.legendItem} key={item.id}>
                <svg width="15" height="15" fill={item.color[600]} viewBox="0 0 20 20">
                  <circle cx="10" cy="10" r="8" />
                </svg>
                {item.name}
              </div>
            ))}
          </div>
        </div>
      );
    }
}
CityBudgetSummaryChart.defaultProps = {
  budgetText: 'Suggested',
  proposalText: 'Your Proposal',
};

export default withStyles(styles)(CityBudgetSummaryChart);
