import _ from 'lodash';
import { Decimal } from 'decimal.js';

function getConsequence(amount, flags) {
  let consequence = null;
  flags.some((flag) => {
    const {
      lower, upper, level, explanation, url,
    } = flag;
    if (!lower && !upper) return false;
    const low = lower || -1;
    const up = upper || Number.MAX_VALUE;
    if (low <= amount && amount < up) {
      consequence = { level, explanation, url };
      return true;
    }
    return false;
  });
  return consequence;
}

function mapToProposalItem(item) {
  const proposalItem = {
    id: item.id,
    amount: item.suggested,
    flags: item.flags,
    diff: 0,
    consequence: getConsequence(item.suggested, item.flags),
    comment: '',
  };
  if (item.subitems) {
    proposalItem.subitems = _.mapValues(item.subitems, (subitem) => ({
      amount: subitem.suggested,
      flags: subitem.flags,
      diff: 0,
      consequence: getConsequence(subitem.suggested, subitem.flags),
    }));
  }
  return proposalItem;
}

function ratioToMin(budgetDetails, item) {
  return item.amount / budgetDetails.items[item.id].min;
}

function ratioToMax(budgetDetails, item) {
  return item.amount / budgetDetails.items[item.id].max;
}

function scaleAll(budgetDetails, proposal) {
  const ratio = proposal.amount / budgetDetails.amount;
  if (ratio > 1) {
    const items = Object.values(proposal.items)
      .sort((a, b) => ratioToMin(budgetDetails, a) - ratioToMin(budgetDetails, b))
      .reduce(({
        items, budgeted, proposed,
      }, curr) => {
        const scaled = new Decimal(curr.amount).times(budgeted).dividedBy(proposed);
        const { min, suggested } = budgetDetails.items[curr.id];
        const amount = Decimal.max(min, scaled);
        budgeted = budgeted.minus(amount);
        proposed = proposed.minus(curr.amount);
        const diff = amount.minus(suggested).toNumber();
        const consequence = getConsequence(amount, curr.flags);
        items[curr.id] = {
          ...curr, amount: amount.toNumber(), diff, consequence,
        };
        return {
          items, budgeted, proposed,
        };
      }, {
        items: {},
        budgeted: new Decimal(budgetDetails.amount),
        proposed: new Decimal(proposal.amount),
      });
    return {
      ...proposal,
      amount: budgetDetails.amount,
      items: items.items,
    };
  }
  if (ratio < 1) {
    const items = Object.values(proposal.items)
      .sort((a, b) => ratioToMax(budgetDetails, b) - ratioToMax(budgetDetails, a))
      .reduce(({
        items, budgeted, proposed,
      }, curr) => {
        const scaled = new Decimal(curr.amount).times(budgeted).dividedBy(proposed);
        const { max, suggested } = budgetDetails.items[curr.id];
        const amount = Decimal.min(max, scaled);
        budgeted = budgeted.minus(amount);
        proposed = proposed.minus(curr.amount);
        const diff = amount.minus(suggested).toNumber();
        const consequence = getConsequence(amount, curr.flags);
        items[curr.id] = {
          ...curr, amount: amount.toNumber(), diff, consequence,
        };
        return {
          items, budgeted, proposed,
        };
      }, {
        items: {},
        budgeted: new Decimal(budgetDetails.amount),
        proposed: new Decimal(proposal.amount),
      });
    return {
      ...proposal,
      amount: budgetDetails.amount,
      items: items.items,
    };
  }
  return proposal;
}

export default {
  getConsequence,
  mapToProposalItem,
  scaleAll,
};
