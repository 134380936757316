import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid, Typography, Popover, Button,
} from '@material-ui/core';

import ConsequenceIcon from './ConsequenceIcon';

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.text.link,
    fontSize: '0.85rem',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    display: 'inline',
    padding: '0 5px',
  },
  popOver: {
    padding: 15,
    minWidth: 100,
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
  },
});

function ConsequenceDetails(props) {
  const [anchor, setAnchor] = useState(null);
  if (props.consequence == null) return null;
  const { classes, consequence } = props;
  const { explanation, url } = consequence;
  const open = anchor != null;
  return (
    <Grid item sm={6} xs={12}>
      <div className={classes.container}>
        <ConsequenceIcon consequence={consequence} />
        <Typography>Negative consequence</Typography>
        <div
          className={classes.link}
          aria-owns={open ? 'simple-popper' : undefined}
          aria-haspopup="true"
          onClick={(event) => { setAnchor(event.currentTarget); }}
        >
          More...
        </div>
      </div>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchor}
        onClose={() => { setAnchor(null); }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className={classes.popOver}>
          <div style={{ flexGrow: 2, marginBottom: 15 }}>
            {explanation || 'Detailed explanation of the consequence.'}
          </div>
          {url && (
            <div style={{ textAlign: 'right' }}>
              <Button variant="outlined" color="primary" href={url} target="_blank">Learn More</Button>
            </div>
          )}
        </div>
      </Popover>
    </Grid>
  );
}

export default withStyles(styles)(ConsequenceDetails);
