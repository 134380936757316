import data from './mock-summary';

const trends = {
  1: [
    {
      year: '2014-2015', Fire: 24077302, Police: 53074668, 'Emergency Communication': 6723332,
    },
    {
      year: '2015-2016', Fire: 25140344, Police: 54581852, 'Emergency Communication': 8189871,
    },
    {
      year: '2016-2017', Fire: 25489534, Police: 57757908, 'Emergency Communication': 8468628,
    },
    {
      year: '2017-2018', Fire: 28736658, Police: 60133011, 'Emergency Communication': 8348560,
    },
    {
      year: '2018-2019', Fire: 34112632, Police: 63883388, 'Emergency Communication': 8638861,
    },
  ],
  2: [
    {
      year: '2016-2017', 'Fleet Management': 3409699, 'General Services': 12776436, 'Parks and Recreation': 13457288, 'Public Works': 18967480, 'Solid Waste Management': 16320570, Transportation: 28325480, 'Water Management': 43986659,
    },
    {
      year: '2017-2018', 'Fleet Management': 3629743, 'General Services': 14283964, 'Parks and Recreation': 14199687, 'Public Works': 22684485, 'Solid Waste Management': 17439971, Transportation: 32181111, 'Water Management': 46705555,
    },
    {
      year: '2018-2019', 'Fleet Management': 3730155, 'General Services': 13184669, 'Parks and Recreation': 14276098, 'Public Works': 22137550, 'Solid Waste Management': 17994804, Transportation: 32875548, 'Water Management': 46364420,
    },
  ],
  3: [],
  4: [],
  5: [],
};

const statements = {
  1: [
    { id: 0, name: 'Fire', text: 'The Durham Fire Department strives to enhance the quality of life for the citizens and visitors' },
    { id: 1, name: 'Police', text: 'To minimize crime, promote safety, and enhance the quality of life in partnership with our community.' },
    { id: 2, name: 'Emergency Communication', text: 'To affirmatively promote, preserve and protect the safety and security of all citizens of the community. It is our commitment to provide citizens with the fastest and most efficient response to emergency calls possible while ensuring the safety of Police, Fire and Emergency Medical Services (EMS) personnel. It is our goal to contribute to the quality of life of our community by giving efficient, reliable, courteous, responsive and professional 911 communications services. We will constantly seek ways to improve the quality of assistance we provide to the community by acknowledging that service is our one and only product and our goal is to provide it at the most superior level possible thereby saving lives, protecting property and helping to stop crimes, thus making Durham a safer community to live, work and visit.' },
  ],
  2: [
    { id: 0, name: 'Fleet Management', text: 'To provide timely, cost effective and high quality services to our customers while achieving the highest levels of customer satisfaction. The Department of Fleet Management consists of three core business functions: Fleet Asset Management, Fleet Maintenance, and Fire Maintenance. The department strives to maintain a high level of compliance with the preventive maintenance program (PM), manage a consolidated vehicle/equipment replacement program, provide 24/7 support to the Fire Department’s suppression vehicles, and 24/7 support for fleet functions during emergency events.' },
    { id: 1, name: 'General Services', text: 'The department’s purpose is to build and maintain city properties on-time and on-budget to make Durham a great place for people to live, work and play.' },
    { id: 2, name: 'Parks and Recreation', text: 'Play More: Connecting our whole community to wellness, the outdoors and lifelong learning.' },
    { id: 3, name: 'Public Works', text: 'From streets to streams, we are committed to building and maintaining infrastructure today for a better tomorrow.' },
    { id: 4, name: 'Solid Waste Management', text: 'To provide industry leading waste collection, recycling and disposal services. We will be responsive, accountable, and dependable to the residents of Durham.' },
    { id: 5, name: 'Transportation', text: 'To serve our citizens, businesses and visitors by ensuring a safe, efficient, accessible and convenient transportation system that meet our City’s interests and enhance the quality of life of our citizens, today and into the future.' },
    { id: 6, name: 'Water Management', text: 'To provide cost-effective water and wastewater services that meet customers’ expectations and all regulatory requirements.' },
  ],
  3: [
    // {name: '', 'text': ''},
  ],
  4: [],
  5: [],
};

const comments = {
  1: [
    {
      id: 0, name: 'Elaine', region: 'Fairfield', text: 'The crime rate is rising, we need to spend more on the police department.',
    },
    {
      id: 1, name: 'Anonymous', region: 'Grandale', text: "I don't think we need so many police around.",
    },
    {
      id: 2, name: 'Frankie', region: 'Northgate Park', text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  ],
  2: [],
  3: [],
  4: [],
  5: [],
};

const items = {
  ...data,
  items: data.items.map((item) => ({
    ...item,
    trend: trends[item.id],
    statement: statements[item.id],
    comments: comments[item.id],
  })),
};

export default items;
