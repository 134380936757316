import {
  AppBar, Grid, Toolbar, Typography, Divider, Link,
} from '@material-ui/core';
import React from 'react';

import { withRouter, Route, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import BudgetIcon from '../BudgetIcon';
import AustinWelcome from './AustinWelcome';
import AustinOverview from './AustinOverview';
import AustinRevenue from './AustinRevenue';
import AustinExpenditure from './AustinExpenditure';
import AustinConsent from './AustinConsent';
import AustinThankyou from './AustinThankyou';
import AustinSurvey from './AustinSurvey';
import ToS from './pages/ToS';
import LanguageSettings from '../LanguageSettings';
import ScrollToTop from '../ScrollToTop';

export const AUSTIN_BASE_URL = '/austin2020';

const styles = (theme) => ({
  main: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  root: {
    flexGrow: 1,
    flex: '1 0 100%',
    maxWidth: 900,
    margin: 'auto',
  },
  headerTitle: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  footer: {
    '& hr': {
      margin: theme.spacing(2, 0),
    },
    textAlign: 'center',
    '& .MuiTypography-root': {
      fontFamily: 'Roboto Condensed',
    },
  },
});

const components = [
  { step: 'overview', component: AustinOverview },
  { step: 'revenue', component: AustinRevenue },
  { step: 'expenditure', component: AustinExpenditure },
  { step: 'consent', component: AustinConsent },
  { step: 'survey', component: AustinSurvey },
];

const defaultOrder = ['overview', 'revenue', 'expenditure', 'consent', 'survey', 'thankyou'];

function getNext(step, order) {
  return `${AUSTIN_BASE_URL}/${order[order.indexOf(step) + 1]}`;
}

const AustinMain = ({ classes }) => {
  const t = useSelector((state) => state.options.t);
  const code = useSelector((state) => state.user.code);
  const sequence = useSelector((state) => (state.user.researchParams || {}).sequence);
  const order = sequence || defaultOrder;
  return (
    <div>
      <ScrollToTop />
      <AppBar position="static" color="inherit">
        <Toolbar>
          <BudgetIcon />
          <Typography variant="h6" color="primary" className={classes.headerTitle}>
            {t('austin.header-title')}
          </Typography>
          <LanguageSettings />
          {/* <div className="help-button">
            <IconButton
              color="inherit"
            >
              <Help />
            </IconButton>
          </div> */}
        </Toolbar>
      </AppBar>
      <div className={classes.main}>
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Route exact path={AUSTIN_BASE_URL} render={() => <AustinWelcome nextUrl={`${AUSTIN_BASE_URL}/overview`} />} />
              {components.map(({ step, component: Component }) => (
                <Route
                  key={step}
                  exact
                  path={`${AUSTIN_BASE_URL}/${step}`}
                  render={(props) => {
                    if (!code) {
                      return <Redirect push to={AUSTIN_BASE_URL} />;
                    }
                    return (<Component {...props} nextUrl={getNext(step, order)} />);
                  }}
                />
              ))}
              <Route exact path={`${AUSTIN_BASE_URL}/thankyou`} component={AustinThankyou} />
              <Route exact path={`${AUSTIN_BASE_URL}/terms`} component={ToS} />
            </Grid>
            <Grid item xs={12} className={classes.footer}>
              <Divider />
              <Typography>
                <Link variant="body2" href="https://voxpopuli.stanford.edu/" target="_blank" rel="noopener">Stanford Crowdsourced Democracy Team</Link>
              </Typography>
              <Link color="secondary" variant="body2" href={`${AUSTIN_BASE_URL}/terms`} target="_blank" rel="noopener">Terms and Services</Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(AustinMain));
