import React from 'react';
import { useSelector } from 'react-redux';
import MarkdownElement from './MarkdownElement';

const emptyRegExp = /^\s*$/;
const vizRegexp = /^"visualization": "(.*)"/;

function getContents(markdown) {
  return markdown
    .split(/^{{("visualization":[^}]*)}}$/gm) // Split markdown into an array, separating demos
    .filter((content) => !emptyRegExp.test(content)); // Remove empty lines
}

export default function MarkdownWithViz(props) {
  const {
    req,
    reqPrefix,
    reqSource,
  } = props;

  const userLanguage = useSelector((state) => state.options.userLanguage);
  const visualizations = {};
  const markdowns = {};
  req.keys().forEach((filename) => {
    if (filename.indexOf('.md') !== -1) {
      const match = filename.match(/-([a-z]{2})\.md$/);
      if (match) {
        markdowns[match[1]] = reqSource(filename).default;
      }
    } else {
      const vizName = `${reqPrefix}/${filename.replace(/\.\//g, '')}`;

      visualizations[vizName] = {
        ...visualizations[vizName],
        js: req(filename).default,
        raw: reqSource(filename),
      };
    }
  });
  const markdown = markdowns[userLanguage] || markdowns.en;

  const contents = getContents(markdown);

  return (
    <>
      {contents.map((content, index) => {
        if (visualizations && vizRegexp.test(content)) {
          let vizOptions;
          try {
            vizOptions = JSON.parse(`{${content}}`);
          } catch (err) {
            console.error('JSON.parse fails with: ', `{${content}}`);
            console.error(err);
            return null;
          }

          const name = vizOptions.visualization;
          if (!visualizations || !visualizations[name]) {
            const errorMessage = [
              `Missing visualization: ${name}. You can use one of the following:`,
              Object.keys(visualizations),
            ].join('\n');

            if (process.env.NODE_ENV !== 'production') {
              console.error(errorMessage);
            }
            return (
              <div key={content}>
                Missing visualization `
                {name}
`
              </div>
            );
          }

          return (
            <div>content</div>
          );
        }

        return <MarkdownElement className="markdownElement" key={index} text={content} />;
      })}
    </>
  );
}
