import React, {
  useState, useCallback, useRef, useLayoutEffect,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  Paper, Grid, Divider, colors, Typography, IconButton, TextField, Hidden, Tooltip, Collapse,
  useScrollTrigger, Box, Button,
} from '@material-ui/core';
import {
  PieChart, Pie, Cell, ResponsiveContainer, Tooltip as ReTooltip, Legend,
} from 'recharts';
import {
  CheckCircle, Warning, FiberManualRecord, Remove, Add, Cancel,
  SettingsBackupRestore, Info, ExpandMore, ExpandLess,
} from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Expenditure from './pages/Expenditure';
import formatters from '../../util/formatters';
import palettes from '../../util/palette';
import { request, log } from '../../api/client';
import expenditure from './chart-data/expenditure.json';
import { updateStepResponse } from '../../actions/response-action';
import AustinNextBar from './AustinNextBar';
import { useSlug, useT, useCode, useUserLanguage, useResponse } from './hooks';

const STEP = 'expenditure';
const threshold = 0.1;
const stepSize = 250;
const maxThreshold = 0.049;
const warnThreshold = 0.03;

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    fontFamily: 'Roboto Condensed',
    textAlign: 'left',
    border: `1px solid ${theme.palette.divider}`,
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
  summary: {
    textAlign: 'center',
  },
  info: {
    fontSize: '1.5rem',
  },
  badge: {
    fontSize: '1.5rem',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconButton: {
    margin: '0 16px',
    border: '1px solid white',
    '&.Mui-disabled': {
      backgroundColor: colors.grey[200],
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    touchAction: 'manipulation',
  },
  collapse: {
    position: 'relative',
    '& .MuiCollapse-wrapperInner': {
      marginBottom: 36,
    },
  },
  pieChart: {
    paddingTop: 0,
    marginTop: theme.spacing(-2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  showMore: {
    position: 'absolute',
    textAlign: 'right',
    left: 0,
    color: colors.blueGrey[600],
    bottom: 0,
    width: 'calc(100% - 16px)',
    padding: 8,
    background: 'white',
    // background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.0),
    //  rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 1) 50%, #FFF)',
  },
});

function getBalanceSummary(classes, t, diff) {
  if (diff > threshold) {
    return (
      <div className={classes.badge}>
        <Cancel style={{ color: colors.red[600], margin: '0 5px' }} />
        {`${formatters.kmb(diff)} ${t('austin.expenditure.surplus')}`}
      </div>
    );
  } if (diff < -threshold) {
    return (
      <div className={classes.badge}>
        <Cancel style={{ color: colors.red[600], margin: '0 5px' }} />
        {`${formatters.kmb(-diff)} ${t('austin.expenditure.deficit')}`}
      </div>
    );
  }
  return (
    <div className={classes.badge}>
      <CheckCircle style={{ color: colors.green[600], margin: '0 5px' }} />
      {t('austin.expenditure.balanced')}
    </div>
  );
}

function currentValue(value, diff) {
  return value + diff * 1000;
  // return value * (1 + diff);
}

function tooltipFormatter(value) {
  return `$${formatters.kmb(value)}`;
}


function ExpenditureChart() {
  const t = useT();
  const code = useCode();
  const slug = useSlug();
  const data = expenditure.departments.map((dep) => (
    { ...dep, name: t(dep.fullkey) }
  ));
  const [interactions, setInteractions] = useState([]);
  const onHover = useCallback(({ payload }) => {
    interactions.push(payload.id);
  }, [interactions]);
  const onMouseLeave = useCallback(() => {
    if (interactions.length > 0) {
      log(code, 'CHART_HOVER', { step: STEP, interactions }, slug);
      setInteractions([]);
    }
  }, [code, interactions, setInteractions, slug]);
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart onClick={() => { }} onMouseLeave={onMouseLeave}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius="80%"
          fill="#8884d8"
          dataKey="value"
          nameKey="name"
          onMouseEnter={onHover}
        >
          {
            data.map((entry, index) => <Cell key={`cell-${entry.id}`} fill={palettes[index % palettes.length][400]} />)
          }
        </Pie>
        <ReTooltip formatter={tooltipFormatter} />
        <Legend />
      </PieChart>

    </ResponsiveContainer>
  );
}

function getImpact(t, ratio) {
  if (Math.abs(ratio) < 1e-6) {
    return [t('austin.expenditure.current'), Info, colors.grey];
  }
  const significant = Math.abs(ratio) > warnThreshold;
  // eslint-disable-next-line no-nested-ternary
  const color = ratio > 0 ? colors.grey : (significant ? colors.red : colors.amber);
  const extend = significant ? 'significant' : 'moderate';
  const icon = ratio > 0 ? Info : Warning;
  const direction = ratio > 0 ? '+' : '-';
  return [t(`austin.expenditure.${extend}${direction}`), icon, color];
}

function TruncatedText(props) {
  const {
    classes, text, height, onToggle,
  } = props;
  const ref = useRef(null);
  const [clipped, setClipped] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const onResize = useCallback(() => {
    if (ref.current) {
      setClipped(ref.current.getBoundingClientRect().height > height);
    }
  }, [ref, setClipped, height]);
  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);
  useLayoutEffect(() => {
    onResize();
  }, [text, height, ref, onResize]);
  const onClick = useCallback(() => {
    setExpanded(!expanded);
    onToggle(expanded);
  }, [expanded, setExpanded, onToggle]);
  const Icon = expanded ? ExpandLess : ExpandMore;
  return (
    <Collapse in={expanded} collapsedHeight={height} className={classes.collapse}>
      <Box padding={1} ref={ref} onClick={onClick}>
        {text}
      </Box>
      {clipped
        ? (
          <Box className={classes.showMore}>
            <Button size="small" startIcon={<Icon />} onClick={onClick}>
              {expanded ? 'Show Less' : 'Show More'}
            </Button>
          </Box>
        )
        : null}
    </Collapse>
  );
}

function BudgetItem(props) {
  const {
    id, fullkey, value, response, setResponse, classes, index, code,
  } = props;
  const c = palettes[index % palettes.length];
  const t = useT();
  const slug = useSlug();
  const onPlus = useCallback(() => {
    log(code, 'BUTTON_CLICK', { step: STEP, id, button: 'increase' }, slug);
    setResponse({ ...response, [id]: response[id] + stepSize });
  }, [response, setResponse, id, code, slug]);
  const onMinus = useCallback(() => {
    log(code, 'BUTTON_CLICK', { step: STEP, id, button: 'reduce' }, slug);
    setResponse({ ...response, [id]: response[id] - stepSize });
  }, [response, setResponse, id, code, slug]);
  const onReset = useCallback(() => {
    log(code, 'BUTTON_CLICK', { step: STEP, id, button: 'reset' }, slug);
    setResponse({ ...response, [id]: 0 });
  }, [response, setResponse, id, code, slug]);
  const onToggle = useCallback((expanded) => {
    log(code, 'BUTTON_CLICK', {
      step: STEP, id, button: expanded ? 'show less' : 'show more',
    }, slug);
  }, [id, code, slug]);
  const ratio = (response[id] * 1000) / value;
  const [impact, Icon, color] = getImpact(t, ratio);
  return (
    <Grid container style={{ marginBottom: 16 }}>
      <Grid item xs={12}>
        <Typography style={{
          fontFamily: 'Roboto Condensed', fontSize: '1.1rem', marginTop: 8, marginBottom: -8,
        }}
        >
          <FiberManualRecord style={{ marginBottom: -5, color: c[400] }} />
          <b>{t(fullkey)}</b>
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TruncatedText height={100} text={t(`${fullkey}-longdescription`)} classes={classes} onToggle={onToggle} />
      </Grid>
      <Grid item xs={12} sm={8} className={classes.buttonContainer}>
        <Tooltip title={t('austin.expenditure.reset')}>
          <span>
            <IconButton
              className={classes.iconButton}
              disabled={Math.abs(ratio) < 1e-6}
              style={{
                color: colors.grey[400], borderColor: colors.grey[400],
              }}
              onClick={onReset}
            >
              <SettingsBackupRestore />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('austin.expenditure.reduce')}>
          <span>
            <IconButton
              disabled={ratio < -maxThreshold}
              className={classes.iconButton}
              style={{
                color: colors.red[300], borderColor: colors.red[300],
              }}
              onClick={onMinus}
            >
              <Remove />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t('austin.expenditure.increase')}>
          <IconButton
            // disabled={response[id] > maxThreshold}
            className={classes.iconButton}
            style={{
              color: colors.green[300], borderColor: colors.green[300],
            }}
            onClick={onPlus}
          >
            <Add />
          </IconButton>
        </Tooltip>
        <Hidden xsDown>
          <div style={{ flex: '1 1 auto' }}>
            {formatters.kmb(currentValue(value, response[id]))}
            {` (${t('austin.expenditure.your-change')}: ${formatters.kmb(response[id] * 1000, '+')})`}
          </div>
        </Hidden>
      </Grid>
      <Hidden smUp>
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: 16 }}>
          {formatters.kmb(currentValue(value, response[id]))}
          {` (${t('austin.expenditure.your-change')}: ${formatters.kmb(response[id] * 1000, '+')})`}
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={4}>
        <Typography style={{
          fontFamily: 'Roboto Condensed', fontSize: '1rem', marginTop: 8, marginBottom: 8, textAlign: 'center',
        }}
        >
          <Icon style={{ marginBottom: -5, color: color[600] }} />
          {impact}
        </Typography>
      </Grid>
    </Grid>
  );
}

function Comment(props) {
  const {
    response, setResponse, id, label,
  } = props;
  const onChange = useCallback((event) => {
    setResponse({ ...response, [id]: event.target.value });
  }, [response, setResponse, id]);
  return (
    <TextField
      label={label}
      fullWidth
      multiline
      margin="normal"
      variant="outlined"
      value={response[id]}
      onChange={onChange}
    />
  );
}

function toChartData(items, response) {
  const changes = _.omit(response, 'comment');
  const change = _.sum(_.values(changes));
  const [pos, neg] = _.partition(changes, (num) => num >= 0);
  const maxChange = Math.max(_.sum(pos), -_.sum(neg));
  const range = Math.ceil((maxChange + 800) / 10000) * 10000;
  const posItems = [];
  const negItems = [];
  let posSum = 0;
  let negSum = 0;
  _.forOwn(items, (item, index) => {
    const { id, fullkey } = item;
    if (response[id] > 0) {
      const width = (response[id] * 100) / range;
      posItems.push({
        id,
        fullkey,
        color: palettes[index % palettes.length],
        width: `${width}%`,
        start: `${posSum}%`,
        end: `${(posSum + width)}%`,
      });
      posSum += width;
    } else {
      const width = -(response[id] * 100) / range;
      negItems.push({
        id,
        fullkey,
        color: palettes[index % palettes.length],
        width: `${width}%`,
        start: `${negSum}%`,
        end: `${(negSum + width)}%`,
      });
      negSum += width;
    }
  });
  const balanced = change === 0;
  const max = `${(maxChange * 100) / range}%`;
  return {
    posItems, negItems, max, balanced, range,
  };
}

function BalanceProgressBar(props) {
  const h = 15;
  const { items, response } = props;
  const t = useT();
  const {
    posItems, negItems, max, balanced, range,
  } = toChartData(items, response);
  const balanceColor = balanced ? colors.green : colors.red;
  return (
    <div>
      <svg width="100%" height={4 * h}>
        <rect x="0%" y={0} width="100%" height={h + 3} stroke="none" strokeWidth="0" fill={colors.grey[100]} />
        <rect x="0%" y={2 * h - 3} width="100%" height={h + 3} stroke="none" strokeWidth="0" fill={colors.grey[100]} />
        {posItems.map((item) => {
          const {
            id, start, width, color,
          } = item;
          return (
            <g key={id}>
              <rect x={start} y={0} width={width} height={h + 3} stroke="none" strokeWidth="0" fill={color[600]} />
            </g>
          );
        })}
        {negItems.map((item) => {
          const {
            id, start, width, color,
          } = item;
          return (
            <g key={id}>
              <rect x={start} y={2 * h - 3} width={width} height={h + 3} stroke="none" strokeWidth="0" fill={color[600]} />
            </g>
          );
        })}
        <text x="100%" y={h - 2} textAnchor="end">{t('austin.expenditure.budget-increase')}</text>
        <text x="100%" y={3 * h - 5} textAnchor="end">{t('austin.expenditure.budget-reduction')}</text>
        <text x="0%" y={4 * h} textAnchor="start" style={{ fontSize: 'small' }}>0</text>
        <text x="50%" y={4 * h} textAnchor="center" style={{ fontSize: 'small' }}>{`${range / 2000}M`}</text>
        <text x="100%" y={4 * h} textAnchor="end" style={{ fontSize: 'small' }}>{`${range / 1000}M`}</text>
        <line strokeDasharray="2, 2" x1="0%" x2="0%" y1={0} y2={3 * h} stroke={colors.grey[600]} strokeWidth="2" />
        <line x1={max} x2={max} y1={0} y2={3 * h} stroke={balanceColor[600]} strokeWidth="5" />
      </svg>
    </div>
  );
}

function FloatingSummaryBar(props) {
  const {
    allotted, diff, response, classes,
  } = props;
  const t = useT();
  const ref = useRef(null);
  const padding = 16;
  const [threshold, setThreshold] = useState(670);
  const [width, setWidth] = useState(866);
  const [height, setHeight] = useState(null);
  const onResize = useCallback(() => {
    if (ref.current) {
      setThreshold(ref.current.parentElement.getBoundingClientRect().y + window.scrollY);
      setWidth(ref.current.parentElement.clientWidth - padding);
      setHeight(ref.current.clientHeight - 2 * padding);
    }
  }, [setThreshold, ref]);
  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);
  const trigger = useScrollTrigger({ threshold, disableHysteresis: true });
  return (
    <>
      <Paper
        elevation={trigger ? 4 : 0}
        style={{
          margin: -padding, padding, position: trigger ? 'fixed' : 'static', top: padding, width, zIndex: 5,
        }}
        ref={ref}
      >
        <Grid container spacing={1} className={`${classes.summary} `}>
          <Grid item xs={6} sm={4}>
            <div>{t('austin.expenditure.current-budget')}</div>
            <div className={classes.info}>
              {formatters.kmb(expenditure.total)}
            </div>
          </Grid>
          <Grid item xs={6} sm={4}>
            <div>{t('austin.expenditure.your-budget')}</div>
            <div className={classes.info}>
              {formatters.kmb(allotted)}
            </div>
          </Grid>
          <Grid item xs={12} sm={4} className="budget-balance">
            {
              getBalanceSummary(classes, t, diff)
            }
          </Grid>
          <Grid item xs={12}>
            <BalanceProgressBar
              items={expenditure.departments}
              response={response}
              target={expenditure.total}
              classes={classes}
            />
          </Grid>
        </Grid>
      </Paper>
      {trigger && height ? <div style={{ height }} /> : null}
    </>
  );
}

function AustinExpenditure(props) {
  const { classes, nextUrl } = props;
  const t = useT();
  const [error, setError] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const code = useCode();
  const slug = useSlug();
  const userLanguage = useUserLanguage();
  const [response, setResponse] = useState(useResponse(STEP));
  const next = useCallback(
    () => {
      dispatch(updateStepResponse({ step: STEP, response }));
      request('/api/save', 'POST', {
        code, step: STEP, lang_tag: userLanguage, response: JSON.stringify(response), slug
      }).then((response) => {
        if (!response.ok) {
          setError('error-next');
        } else {
          history.push(nextUrl);
        }
      });
    },
    [dispatch, code, userLanguage, setError, history, response, nextUrl, slug],
  );
  const allotted = expenditure.departments.map((dep) => (
    currentValue(dep.value, response[dep.id])
  )).reduce((a, b) => a + b);
  const diff = expenditure.total - allotted;

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Expenditure />
        </Grid>
        <Grid item xs={12} className={classes.pieChart}>
          <ExpenditureChart response={response} />
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <FloatingSummaryBar
            classes={classes}
            allotted={allotted}
            diff={diff}
            response={response}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ padding: 16 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{ lineHeight: '1.5rem' }}>
                  {
                    expenditure.departments.map((entry, index) => (
                      <BudgetItem
                        key={entry.id}
                        index={index}
                        {...entry}
                        code={code}
                        classes={classes}
                        response={response}
                        setResponse={setResponse}
                      />
                    ))
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Divider />
              <Comment
                id="comment"
                label={t('austin.expenditure.comment')}
                response={response}
                setResponse={setResponse}
              />
            </Grid>
          </Grid>
          <AustinNextBar error={error} disabled={Math.abs(diff) > threshold} disabledPrompt="balance-prompt" onClick={next} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(styles)(AustinExpenditure);
