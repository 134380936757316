import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions/introjs-action';

const initialState = {
  enabled: false,
  steps: [
    {
      intro: 'Welcome to the city budget consultation. Please share how you think the city should spend its budget.',
    }, {
      element: '.budget-item-0',
      intro: 'Click on a budget item to see more relevant information and to express your preference.',
    }, {
      element: '.budget-item-0',
      intro: 'You can change how much to spend on the budget item by dragging the slider or clicking on the +/- buttons.  You can also leave a comment on the budget item to explain why you think the budget should be changed.',
    }, {
      element: '.budget-balance',
      intro: 'Your total budget has to be balanced before you can submit.  You can leverage the "help me balance" feature below if you don\'t want to manually balance the budget.',
    }, {
      element: '.help-button',
      intro: 'That\'s it!  You can return to this guide anytime by clicking this help button.  Thank you again for participating in the budget consultation.',
    },
  ],
};

const introjsReducer = createReducer(initialState, {
  [actions.startIntro]: (state) => {
    state.enabled = true;
  },
  [actions.endIntro]: (state) => {
    state.enabled = false;
  },
});

export default introjsReducer;
